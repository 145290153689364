/**
 * documentationsidebar reducer.
 */

import { EXPAND_DOCUMENTATIONSIDEBAR } from '../constants/ActionTypes';

const initialState = {
  expanded: false,
};

export default function myreducer(state = initialState, action = {}) {
  switch (action.type) {
    case EXPAND_DOCUMENTATIONSIDEBAR:
      return {
        expanded: action.isExpanded,
      };
    default:
      return state;
  }
}
