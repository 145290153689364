import React from 'react';
import { Button, Container, Input, Segment } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { flattenToAppURL } from '@plone/volto/helpers';
import { getAllBookmarksAdmin } from '@package/actions';
// import { deStringifySearchquery } from '@plone-collective/volto-bookmarks/helpers';

function bookmarkSorter(a, b) {
  const titleA = a.owner + a['@id'] + a.payload.querystringvalues;
  const titleB = b.owner + b['@id'] + b.payload.querystringvalues;
  if (titleA < titleB) {
    return -1;
  } else if (titleA > titleB) {
    return 1;
  }
  return 0;
}

// function getTitle(queryparams) {
//   const searchParams = new URLSearchParams(deStringifySearchquery(queryparams));
//   const filters = searchParams.getAll('f');
//   const query = searchParams.get('q');

//   const title_array = [];
//   let section = '';
//   if (query) {
//     title_array.push(`«${query}»`);
//   }
//   filters.forEach((el) => {
//     let foo = el.split(':');
//     if (foo[0] !== 'section') {
//       title_array.push(foo[0]);
//     } else {
//       section = foo[1];
//     }
//   });

//   let search_bookmark_title = title_array.join(', ');
//   search_bookmark_title = section
//     ? `${search_bookmark_title} in ${section}`
//     : search_bookmark_title;

//   return search_bookmark_title;
// }

const EditBookmarks = () => {
  const dispatch = useDispatch();
  const bookmarks = useSelector(
    (state) => state.collectivebookmarksadmin.bookmarks || {},
  );

  React.useEffect(() => {
    dispatch(getAllBookmarksAdmin());
  }, [dispatch]);

  function exportBookmarks(data) {
    const fileData = JSON.stringify(data);
    const blob = new Blob([fileData], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = 'bookmarks.json';
    link.href = url;
    link.click();
  }

  return (
    <div>
      <h2>Edit {bookmarks.items?.length} bookmarks</h2>
      <Container>
        <Segment textAlign="center">
          <Button
            onClick={() => {
              exportBookmarks(bookmarks);
            }}
          >
            Export bookmarks
          </Button>
        </Segment>
      </Container>

      <Container>
        <Segment textAlign="left">
          <ul>
            {bookmarks.items?.sort(bookmarkSorter).map((item) => {
              let urlsearchparams = new URLSearchParams(item.queryparams);
              const url = `${
                flattenToAppURL(item['@id']) +
                (Object.keys(item.queryparams).length > 0
                  ? `?${urlsearchparams.toString()}`
                  : '')
              }`;
              let key = `${item.owner}-${item.group}-${url}`;

              return (
                <li key={key}>
                  <a target="_blank" rel="noreferrer" href={url}>
                    {url.startsWith('/suche')
                      ? `query – ${item.title}` // ${getTitle(item.queryparams)}
                      : `simple – ${item.title}`}{' '}
                    <b>{item.owner}</b>
                  </a>
                  <br />
                  <Input
                    type="text"
                    defaultValue={url}
                    id={key}
                    fluid
                    // action={{
                    //   content: 'Save',
                    //   onClick: () => saveBookmarkHandler(item),
                    // }}
                  />
                </li>
              );
            })}
          </ul>
        </Segment>
      </Container>
      {/* <p>{JSON.stringify(bookmarks)}</p> */}
    </div>
  );
};

export default EditBookmarks;
