import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { getVocabulary } from '@plone/volto/actions';
import { Icon } from '@plone/volto/components';
import downSVG from '@plone/volto/icons/download.svg';

import { listUsersPlus } from '../../actions';

import config from '@plone/volto/registry';

/**
 * Apply button "export users" by
 
      <Plug pluggable="main.toolbar.top" id="export-users">
        <ExportUsers />
      </Plug>

 * @returns 
 */

const ExportUsers = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.listUsersPlus?.result);
  const personfields_array = useSelector(
    (state) =>
      state.vocabularies[
        config.settings.rohbergVoltoTweaksRohberg?.personfieldsVocabulary
      ]?.items || [],
  );
  const [fileDownloadUrl, setFileDownloadUrl] = React.useState(null);
  const downloadRef = useRef(null);

  React.useEffect(() => {
    dispatch(listUsersPlus());
    dispatch(
      getVocabulary({
        vocabNameOrURL:
          config.settings.rohbergVoltoTweaksRohberg?.personfieldsVocabulary ||
          '',
      }),
    );
  }, [dispatch]);

  // download is triggered: do download
  React.useEffect(() => {
    if (fileDownloadUrl) {
      downloadRef.current.click();
      URL.revokeObjectURL(fileDownloadUrl);
      setFileDownloadUrl('');
    }
  }, [fileDownloadUrl]);

  const handleExport = () => {
    // export fields of vocabulary config.settings.rohbergVoltoTweaksRohberg?.personfieldsVocabulary plus some more
    let columns = personfields_array.concat([
      { label: 'Gruppen', value: 'groups' },
      { label: 'Benutzername', value: 'username' },
    ]);

    let data = [];

    let header = columns.map((el) => el.label);
    data.push(header);

    users.map((user) => {
      let userrow = columns.map((el) => {
        let val = user[el.value];
        val = Array.isArray(val) ? val.join(',') : val;
        val = val || '';
        val = `"${val}"`;
        return val;
      });
      data.push(userrow);
      return null;
    });

    const csvdata = new Blob([data.join('\n')], {
      encoding: 'UTF-8',
      type: 'text/csv;charset=UTF-8',
    });

    const fileDownloadUrl = window.URL.createObjectURL(csvdata);
    // trigger download
    setFileDownloadUrl(fileDownloadUrl);
  };

  return (
    <>
      {/* DO NOT HELPER ANCHOR! */}
      <a
        style={{ display: 'none' }}
        download="export_users.csv"
        href={fileDownloadUrl}
        ref={downloadRef}
      >
        download it
      </a>
      <Button className="export-users" onClick={handleExport}>
        <Icon
          name={downSVG}
          className="circled"
          size="30px"
          title="Export Users"
        />
      </Button>
    </>
  );
};

export default ExportUsers;
