/**
 * Define Shortcuts
 *
 * Install via config.settings.AppExtras
 */

import { useCallback, useEffect } from 'react';

const Shortcuts = () => {
  const handleKeyPress = useCallback((event) => {
    if (
      (navigator.userAgent.indexOf('Mac OS X') === -1 &&
        event.ctrlKey === true) ||
      (navigator.userAgent.indexOf('Mac OS X') !== -1 && event.metaKey === true)
    ) {
      if (event.key === 'k') {
        event.preventDefault();
        document.querySelector('#global-search-button-wrapper a').click();
      } else if (event.key === 'j') {
        event.preventDefault();
        window.scrollTo(0, 0);
        document.querySelector('div.bookmarkmenu') &&
          document.querySelector('div.bookmarkmenu').click();
      }
    }
  }, []);

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);
  return <></>;
};

export default Shortcuts;
