/**
 * Header component.
 * @module components/theme/Header/Header
 * changes:
 * - Hauptnavigation,
 * - Link auf allgemeine Suche
 */

import React, { Component } from 'react';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import { defineMessages } from 'react-intl';
import OutsideClickHandler from 'react-outside-click-handler';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Container, Menu, Segment } from 'semantic-ui-react';

import { Icon, Logo, Navigation } from '@plone/volto/components';
import searchSVG from '~/icons/search.svg';
import enterSVG from '~/icons/enter_black.svg';
import exitSVG from '~/icons/exit_black.svg';

import { HeroImage } from '~/components/HeroImage';
import { ShowBookmarksContentButton } from '@plone-collective/volto-bookmarks/components';
import UniversalLink from 'customizations/components/manage/UniversalLink/UniversalLink';

const messages = defineMessages({
  closeMobileMenu: {
    id: 'Close menu',
    defaultMessage: 'Close menu',
  },
  openMobileMenu: {
    id: 'Open menu',
    defaultMessage: 'Open menu',
  },
});

/**
 * Header component class.
 * @class Header
 * @extends Component
 */
class Header extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    token: PropTypes.string,
    pathname: PropTypes.string.isRequired,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    token: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      isMobileMenuOpen: false,
    };
  }

  // const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  // see dropdown menu Navigation
  toggleMobileMenu = () => {
    this.setState({ isMobileMenuOpen: !this.state.isMobileMenuOpen });
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  // TODO image small and big for responsive
  render() {
    let today = new Date();
    let weekday = today.getDay();
    let activeItem = this.props.pathname.startsWith('/inside')
      ? 'inside'
      : this.props.pathname.startsWith('/dokumentation')
      ? 'dokumentation'
      : 'home';
    return (
      <Segment className={'top-wrapper weekday-' + weekday}>
        <div className="hero-image-wrapper">
          <HeroImage path={this.props.pathname} />
        </div>
        <Container className="header-container">
          <Segment basic className="header-wrapper" role="banner">
            <div className="header">
              <div className="logo-nav-wrapper">
                <div className="logo">
                  <Logo />
                </div>
              </div>
              <div className="internal-navigation-wrapper">
                <OutsideClickHandler
                  onOutsideClick={() => {
                    if (this.state.isMobileMenuOpen) {
                      this.setState({ isMobileMenuOpen: false });
                    }
                  }}
                >
                  {this.props.token && (
                    <Menu secondary borderless>
                      <Menu.Item
                        as={NavLink}
                        to="/"
                        name="home"
                        className={cx({
                          activeNavLink: activeItem === 'home',
                        })}
                      >
                        <FormattedMessage
                          id="Website"
                          defaultMessage="Website"
                        />
                      </Menu.Item>
                      <Menu.Item
                        as={NavLink}
                        to="/inside"
                        name="inside"
                        className={cx({
                          activeNavLink: activeItem === 'inside',
                        })}
                      >
                        <FormattedMessage
                          id="Intern"
                          defaultMessage="IGIB-Inside"
                        />
                      </Menu.Item>
                      <Menu.Item
                        as={NavLink}
                        to="/dokumentation"
                        name="dokumentation"
                        className={cx({
                          activeNavLink: activeItem === 'dokumentation',
                        })}
                      >
                        <FormattedMessage
                          id="Dokumentation"
                          defaultMessage="Dokumentation"
                        />
                      </Menu.Item>
                    </Menu>
                  )}

                  <Menu secondary borderless className="bookmarksmenubutton">
                    <Menu.Item
                      id="global-search-button-wrapper"
                      key="global-search-button-wrapper"
                      name="searchbutton"
                    >
                      <UniversalLink
                        href="/suche"
                        title="Suche"
                        id="'goto-search-button"
                      >
                        <Icon name={searchSVG} size="30px" />
                      </UniversalLink>
                    </Menu.Item>
                    {this.props.token ? (
                      <Menu.Item
                        id="bookmarksmenubutton-wrapper"
                        key="bookmarksmenubutton-wrapper"
                        name="bookmarksmenubuttonwrapper"
                      >
                        <ShowBookmarksContentButton
                          token={this.props.token}
                          intl={this.props.intl}
                        />
                      </Menu.Item>
                    ) : null}
                    <Menu.Item
                      id="loginmenubutton-wrapper"
                      key="loginmenubutton-wrapper"
                      name="loginmenubuttonwrapper"
                    >
                      {this.props.token ? (
                        <UniversalLink href="/logout" title="Ausloggen">
                          <Icon name={exitSVG} size="30px" />
                        </UniversalLink>
                      ) : (
                        <UniversalLink href="/login" title="Einloggen">
                          <Icon name={enterSVG} size="30px" />
                        </UniversalLink>
                      )}
                    </Menu.Item>
                  </Menu>
                </OutsideClickHandler>
              </div>
            </div>
          </Segment>
        </Container>

        <Container className="navigation-container">
          <Segment
            className="navigation-wrapper"
            role="navigation"
            aria-label="Navigation"
            basic
          >
            <Navigation pathname={this.props.pathname} />
          </Segment>
        </Container>
      </Segment>
    );
  }
}

export default compose(
  injectIntl,
  connect((state) => ({
    token: state.userSession.token,
  })),
)(Header);
