import { defineMessages } from 'react-intl';
import { makeInlineElementPlugin } from '@plone/volto-slate/elementEditor';
import PluginElement from './PluginElement';
import { WarningEditorSchema } from './schema';
import { WARNING } from './constants';
import { withWarning } from './extensions';
import warningSVG from '@plone/volto/icons/warning.svg';

import './warning.less';

const messages = defineMessages({
  edit: {
    id: 'Edit text mark',
    defaultMessage: 'Edit text mark',
  },
  delete: {
    id: 'Remove text mark',
    defaultMessage: 'Remove text mark',
  },
});

export default function installPlugin(config) {
  const opts = {
    title: 'Marker',
    pluginId: WARNING,
    elementType: WARNING,
    element: PluginElement,
    isInlineElement: true,
    editSchema: WarningEditorSchema,
    extensions: [withWarning],
    hasValue: (formData) => !!formData,
    toolbarButtonIcon: warningSVG,
    messages,
  };
  const [installEditor] = makeInlineElementPlugin(opts);
  config = installEditor(config);
  return config;
}
