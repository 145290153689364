import React from 'react';
import cx from 'classnames';
import { map } from 'lodash';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Accordion } from 'semantic-ui-react';
import AnimateHeight from 'react-animate-height';
import { Icon } from '@plone/volto/components';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import downSVG from '@plone/volto/icons/down-key.svg';
import rightSVG from '@plone/volto/icons/right-key.svg';

function getSimplifiedHeadingBlocksList(properties) {
  let foo = map(properties.blocks_layout?.items || [], (block) => {
    let bar = properties['blocks'][block];
    if (bar) {
      bar.id = block;
      return bar;
    }
    return null;
  });
  let blocks = foo.filter(
    (block) =>
      block &&
      block['@type'] === 'slate' &&
      block.value &&
      ['h2'].indexOf(block.value[0].type) > -1,
  );
  let topheadingblocks = map(blocks, (block) => ({
    type: block.value[0].type,
    text: block.value[0].children[0].text,
    id: block.id,
    children: [],
    pageurl: properties['@id'],
  }));
  return topheadingblocks;
}

const ListingTOCItem = ({
  content,
  isEditMode,
  showEffective,
  showDescription,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [activeIndex, setActiveIndex] = React.useState(null);

  React.useEffect(() => {
    if (content['@id'] === location.pathname) {
      setActiveIndex(0);
    }
  }, [dispatch, content, location]);

  function accordionClickHandler() {
    const newIndex = activeIndex === 0 ? -1 : 0;
    setActiveIndex(newIndex);
  }

  const topheaderblocks = getSimplifiedHeadingBlocksList(content);

  const scrollWithOffset = (el, offset = null) => {
    let yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    if (window.innerWidth < 767) {
      yCoordinate = yCoordinate - 400;
    }
    const yOffset = offset ? -offset : 0;
    window.scrollTo(0, yCoordinate + yOffset, { behavior: 'smooth' });
  };

  return (
    <div
      className={cx(`listing-body ${content.review_state}`, {
        active: content['@id'] === location.pathname,
      })}
    >
      <Accordion>
        <Accordion.Title active={activeIndex === 0} index={0}>
          <div
            role="button"
            tabIndex={0}
            className={cx('accordiontoggledidu', {
              noTOC: topheaderblocks?.length === 0,
            })}
            onClick={accordionClickHandler}
            onKeyPress={accordionClickHandler}
          >
            <Icon name={activeIndex === 0 ? downSVG : rightSVG} size="25px" />
          </div>
          <ConditionalLink
            to={
              flattenToAppURL(content['@id']) +
              (content['@type'] === 'Manual' ? '#manual' : '')
            }
            condition={!isEditMode}
            scroll={(el) => scrollWithOffset(el)}
            className={
              content['@id'] === location.pathname
                ? 'pagelink active'
                : 'pagelink'
            }
          >
            <div className={`${content.review_state}`}>{content.title}</div>
            {showEffective ? (
              <>
                {content.effective && !content.start && (
                  <p className="listingdate discreet">
                    {moment(content.effective).format('DD.MM.YYYY')}
                  </p>
                )}
                {content.start && (
                  <p className="listingdate discreet">
                    {moment(content.start).format('DD.MM.YYYY')}
                  </p>
                )}
              </>
            ) : null}
            {showDescription ? (
              <p className="description">{content.description}</p>
            ) : null}
          </ConditionalLink>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <AnimateHeight
            animateOpacity
            duration={500}
            height={activeIndex === 0 ? 'auto' : 0}
          >
            {topheaderblocks.map((el) => {
              return (
                <div className="tocelement" key={el.id}>
                  <ConditionalLink
                    to={`${el.pageurl}#${el.id}`}
                    condition={!isEditMode}
                    scroll={(el) => scrollWithOffset(el, 25)}
                    className={`pagelinktoc ${content.review_state}`}
                  >
                    {el.text}
                  </ConditionalLink>
                </div>
              );
            })}
          </AnimateHeight>
        </Accordion.Content>
      </Accordion>
    </div>
  );
};

export default ListingTOCItem;
