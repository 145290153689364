import React, { useEffect, useRef } from 'react';
import jwtDecode from 'jwt-decode';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { Checkbox, Container } from 'semantic-ui-react';
import { getUser, updateUser } from '@plone/volto/actions';
import { Toast } from '@plone/volto/components';
import EditSubscription from '@rohberg/volto-breakingnews/components/EditSubscription';

const messages = defineMessages({
  userConfiguration: {
    id: 'userConfiguration',
    defaultMessage: 'Configuration',
  },
  glossaryTooltips: {
    id: 'glossaryTooltips',
    defaultMessage: 'Show glossary tooltips',
  },
  success: {
    id: 'Success',
    defaultMessage: 'Success',
  },
  configUpdated: {
    id: 'configUpdated',
    defaultMessage: 'Configuration updated.',
  },

  // Overwrite breakingnews
  editsubscriptionheader: {
    id: 'editsubscriptionheader',
    defaultMessage: 'Your Subscriptions',
  },
  editsubscriptionprolog: {
    id: 'editsubscriptionprolog',
    defaultMessage: 'Edit your subscriptions according to your needs.',
  },
});

const UserConfiguration = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.userSession?.token);
  const currentuser = useSelector((state) => state.users?.user);
  const [showtooltips, setShowtooltips] = React.useState(
    currentuser.glossarytooltips,
  );

  const prevShowtooltipsRef = useRef();
  useEffect(() => {
    if (prevShowtooltipsRef.current !== undefined && currentuser?.id) {
      dispatch(
        updateUser(currentuser?.id, {
          glossarytooltips: showtooltips,
        }),
      )
        .then((resp) => {
          let userid = token ? jwtDecode(token).sub : '';
          dispatch(getUser(userid));
        })
        .then(() => {
          toast.success(
            <Toast
              success
              title={intl.formatMessage(messages.success)}
              content={intl.formatMessage(messages.configUpdated)}
            />,
          );
        })
        .catch((error) => {
          console.error(error);
        });
    }
    prevShowtooltipsRef.current = showtooltips;
  }, [dispatch, showtooltips, currentuser.id]);

  return (
    <Container className="view-wrapper manage-view">
      <h1 className="documentFirstHeading">
        <FormattedMessage
          id="userConfiguration"
          defaultMessage="Configuration"
        />
      </h1>

      <div>
        {currentuser?.id ? (
          <Checkbox
            toggle
            label={intl.formatMessage(messages.glossaryTooltips)}
            defaultChecked={showtooltips}
            onChange={() => {
              setShowtooltips(!showtooltips);
            }}
          />
        ) : null}
      </div>
      <div className="spacer3" />
      <div>
        <EditSubscription />
      </div>
    </Container>
  );
};

export default UserConfiguration;
