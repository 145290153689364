// restapi endpoint "usersplus" from rohberg.igib

import { LIST_USERS_PLUS } from '../constants/ActionTypes';

export function listUsersPlus(query) {
  return {
    type: LIST_USERS_PLUS,
    request: query
      ? { op: 'get', path: `/@usersplus?query=${query}` }
      : { op: 'get', path: '/@usersplus' },
  };
}
