import React from 'react';

import { FormFieldWrapper } from '@plone/volto/components';

const TextlinesWidget = (props) => {
  let lineslist = props.value?.items || [];
  return (
    <FormFieldWrapper {...{ id: 'textlines', title: 'History' }}>
      {lineslist?.map((line, idx) => {
        return (
          <div key={idx}>
            <b>{line[0]}</b>
            <br />
            <i>{line[1]}</i>
            <br />
            {line.slice(2).map((el) => (
              <div>{el}</div>
            ))}
          </div>
        );
      })}
    </FormFieldWrapper>
  );
};

export default TextlinesWidget;
