/**
 * SharingGroups component
 * Show groups this instance is shared with.
 * Include inherited configuration from direct parent
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import { flattenToAppURL } from '@plone/volto/helpers';

import { getSharingPlus } from '@rohberg/volto-tweaks-rohberg/actions';

const SharingGroups = ({ content }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.userSession?.token);
  const sharing = useSelector(
    (state) => state.groupsharingcontents.subrequests[content.UID]?.data,
  );
  const sharingParent = useSelector(
    (state) => state.groupsharingcontents.subrequests['parent']?.data,
  );

  let sharing_groups = sharing?.entries || [];
  sharing_groups = sharing_groups.concat(sharingParent?.entries || []);
  sharing_groups = sharing_groups
    ? sharing_groups
        .filter((el) => el.roles.Reader === true)
        .map((el) => el.title)
        .join(', ')
    : '';

  React.useEffect(() => {
    if (token) {
      dispatch(getSharingPlus(flattenToAppURL(content['@id']), content.UID));
      if (content.parent['@id']) {
        dispatch(
          getSharingPlus(flattenToAppURL(content.parent['@id']), 'parent'),
        );
      }
    }
  }, [dispatch, content, token]);

  return sharing_groups ? (
    <div className="metadata">
      <br />
      <FormattedMessage id="shared with" defaultMessage="geteilt mit" />{' '}
      <span>{sharing_groups}</span>
    </div>
  ) : (
    <></>
  );
};

export default SharingGroups;
