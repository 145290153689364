import React from 'react';
import { find, groupBy, intersection } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { defineMessages } from 'react-intl';
import { getVocabulary } from '@plone/volto/actions';
import { ViewDefaultBlock } from '@plone/volto/components';
import './restrictor.less';

const messages = defineMessages({
  sharedWith: {
    id: 'shared with',
    defaultMessage: 'shared with',
  },
});

// Restrict block to groups
export const withRestriction = (view) => {
  const BlockView = view || ViewDefaultBlock;

  return (props) => {
    if (props.data.restrictorGroups && props.data.restrictorGroups.length > 0) {
      const dispatch = useDispatch();
      React.useEffect(() => {
        dispatch(
          getVocabulary({
            vocabNameOrURL: 'plone.app.vocabularies.Groups',
          }),
        );
      }, [dispatch]);
      const allgroups = useSelector(
        (state) => state.vocabularies['plone.app.vocabularies.Groups']?.items,
      );
      const object_actions = useSelector(
        (state) => state.actions?.actions?.object || [],
      );
      const editAction = find(object_actions, { id: 'edit' });
      const currentuser = useSelector((state) => state.users?.user);
      const usergroups = currentuser.groups
        ? currentuser.groups.items.map((el) => el.id)
        : [];
      const joinedGroups = intersection(
        usergroups,
        props.data.restrictorGroups,
      );
      const isGroupMember = joinedGroups.length > 0;
      if (isGroupMember || editAction) {
        const groupMap = groupBy(allgroups, (el) => el.value);
        const restrictorGroupsLabels = props.data.restrictorGroups.map(
          (el) => groupMap[el] && groupMap[el][0].label,
        );
        return (
          <div className="shared">
            <div className="sharing">
              {props.intl.formatMessage(messages.sharedWith)}{' '}
              {restrictorGroupsLabels.join(', ')}
            </div>
            <BlockView {...props} />
          </div>
        );
      } else {
        return null;
      }
    } else {
      return <BlockView {...props} />;
    }
  };
};
