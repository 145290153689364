/**
 * Bookmark actions
 */

import { GET_BOOKMARKS_ADMIN } from '../constants/ActionTypes';

/**
 * Get list of bookmarks
 * @param {string} group
 */
export function getAllBookmarksAdmin() {
  let path = '/@adminbookmarks';
  return {
    type: GET_BOOKMARKS_ADMIN,
    request: {
      op: 'get',
      path: path,
    },
  };
}
