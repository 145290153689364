import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { map } from 'lodash';

import { Container } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import PDFManual2 from './pdf/PDFManual2';

import config from '@plone/volto/registry';

import {
  applyBlockDefaults,
  getBlocksFieldname,
  getBlocksLayoutFieldname,
  getBaseUrl,
} from '@plone/volto/helpers';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import StyleWrapper from '@plone/volto/components/manage/Blocks/Block/StyleWrapper';
import { ViewDefaultBlock } from '@plone/volto/components';
import { MetaInfoToolbar, SharingGroups } from '~/components';

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
  invalidBlock: {
    id: 'Invalid Block',
    defaultMessage: 'Invalid block - Will be removed on saving',
  },
});

const ManualView = (props) => {
  const { content, intl, location, metadata, token, history } = props;
  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);
  const blocksConfig = props.blocksConfig || config.blocks.blocksConfig;

  let flts = {
    kompasscomponent: {
      label: 'Komponente',
      bucket: 'kompasscomponent_agg',
    },
    targetaudience: {
      label: 'Zielgruppe',
      bucket: 'targetaudience_agg',
    },
    organisationunit: {
      label: 'Organisationseinheit',
      bucket: 'organisationunit_agg',
    },
  };
  let filterkeys = Object.keys(flts).filter((el) => content[el]?.length > 0);

  // let kompasscomponents = content.kompasscomponent || [];

  // TODO informationtype: link to siblings with same informationtype
  return (
    <>
      <Container id="manual" key="manual">
        {token ? (
          <MetaInfoToolbar
            content={content}
            location={location}
            token={token}
            history={history}
          />
        ) : null}
        <h4 className="spitzmarke">
          {content.informationtype?.map((item, index) => {
            let tito = item.title || item.token;
            return (
              <UniversalLink
                key={tito}
                href={
                  `/suche?q=&f=` +
                  'informationtype_agg' +
                  `%3A${item.token}&l=list&p=1`
                }
              >
                {tito}
                {index < content['informationtype'].length - 1 ? ', ' : null}
              </UniversalLink>
            );
          })}
        </h4>
        <h1
          id="manual_title"
          className={'documentFirstHeading ' + content.review_state}
        >
          {content.title}
        </h1>
        <div className="documentFirstHeadingBorder">&nbsp;</div>
        {content.description && (
          <p className="documentDescription">{content.description}</p>
        )}
        <SharingGroups content={content} />
        <div className="metadata">
          {filterkeys.map((flt, fltindex) => {
            // return result[flt]?.length ? (
            return true ? (
              <React.Fragment key={fltindex}>
                <span>{flts[flt].label}: </span>
                {content[flt]?.map((item, index) => {
                  let tito = item.title || item.token;
                  return (
                    <Link
                      key={tito}
                      to={
                        `/suche?q=&f=` +
                        flts[flt].bucket +
                        `%3A${item.token}&l=list&p=1`
                      }
                    >
                      {tito}
                      {index < content[flt].length - 1 ? ', ' : null}
                    </Link>
                  );
                })}
                {fltindex < filterkeys.length - 1 && (
                  <span className="metadataseparator">|</span>
                )}
              </React.Fragment>
            ) : null;
          })}
          {content.freemanualtags?.length ? (
            <div className="freemanualtags">
              <span>Tags: </span>
              {content.freemanualtags?.map((item, index) => {
                let tito = item;
                return (
                  <Link key={tito} to={`/suche?q=${tito}`}>
                    {tito}
                    {index < content.freemanualtags.length - 1 ? (
                      ', '
                    ) : (
                      <span></span>
                    )}
                  </Link>
                );
              })}
            </div>
          ) : null}
        </div>

        <div id="metainfocontent" className="pagebreakAfter"></div>

        <div id="blocks">
          {map(
            content[blocksLayoutFieldname].items.filter(
              (block) =>
                content[blocksFieldname]?.[block]?.['@type'] !== 'title',
            ),
            (block) => {
              const Block =
                blocksConfig[content[blocksFieldname]?.[block]?.['@type']]
                  ?.view || ViewDefaultBlock;
              const blockData = applyBlockDefaults({
                data: content[blocksFieldname][block],
                intl,
                metadata,
                properties: content,
              });
              return Block ? (
                <StyleWrapper
                  key={block}
                  {...props}
                  id={block}
                  block={block}
                  data={blockData}
                >
                  <Block
                    id={block}
                    metadata={metadata}
                    properties={content}
                    data={blockData}
                    path={getBaseUrl(location?.pathname || '')}
                    blocksConfig={blocksConfig}
                  />
                </StyleWrapper>
              ) : blockData ? (
                <div key={block}>
                  {intl.formatMessage(messages.unknownBlock, {
                    block: content[blocksFieldname]?.[block]?.['@type'],
                  })}
                </div>
              ) : (
                <div key={block}>
                  {intl.formatMessage(messages.invalidBlock)}
                </div>
              );
            },
          )}
        </div>
      </Container>
      {__CLIENT__ && content.manualfile && (
        <Container id="manualpdf" key="manualpdf">
          <PDFManual2 file={content.manualfile} />
        </Container>
      )}
    </>
  );
};

export default injectIntl(ManualView);
