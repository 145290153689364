import React from 'react';
import { compose } from 'redux';
import { isEqual, isFunction } from 'lodash';

import ListingBody from '@plone/volto/components/manage/Blocks/Listing/ListingBody';
import {
  withSearch,
  withQueryString,
} from '@plone/volto/components/manage/Blocks/Search/hocs';

import config from '@plone/volto/registry';

const isfunc = (obj) => isFunction(obj) || typeof obj === 'function';

const _filtered = (obj) =>
  Object.assign(
    {},
    ...Object.keys(obj).map((k) => {
      const reject = k !== 'properties' && !isfunc(obj[k]);
      return reject ? { [k]: obj[k] } : {};
    }),
  );

const blockPropsAreChanged = (prevProps, nextProps) => {
  const prev = _filtered(prevProps);
  const next = _filtered(nextProps);

  return isEqual(prev, next);
};

/**
 * SearchAndManageView
 *
 * View to manage a matrix of settings. Inspired by SearchBlockView
 */

const SearchAndManageView = (props) => {
  const { data, searchData, variation } = props;

  const Layout = variation.view;

  const listingBodyVariation = config.settings.breakingnews
    .search_and_manage_variations
    ? config.settings.breakingnews.search_and_manage_variations.find(
        ({ id }) => id === data.listingBodyTemplate,
      )
    : config.blocks.blocksConfig.listing.variations.find(
        ({ isDefault }) => isDefault,
      );
  const listingBodyData = searchData;

  return (
    <div className="block search">
      <Layout {...props} isEditMode={false}>
        <ListingBody
          variation={listingBodyVariation}
          data={listingBodyData}
          path={props.path}
          isEditMode={false}
        />
      </Layout>
    </div>
  );
};


// export default compose(withQueryString, withSearch())(SearchAndManageView);

export const SearchAndManageViewComponent = compose((Component) =>
  React.memo(Component, blockPropsAreChanged),
)(SearchAndManageView);
export default compose(
  withQueryString,
  withSearch(),
)(SearchAndManageViewComponent);
