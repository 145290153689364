/**
 * Changes
 * - Add relatedContent
 * - Add Sharing groups
 * - Replace AllButTitleBlocks
 */
/**
 * NewsItemView view component.
 * @module components/theme/View/NewsItemView
 */

import { FormattedDate } from '@plone/volto/components';
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { Container, Header, List, Image, Segment } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';

import {
  AllButTitleBlocks,
  MetaInfoToolbar,
  SharingGroups,
} from '~/components';

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
  relatedContent: {
    id: 'relatedContent',
    defaultMessage: 'Maybe also interesting',
  },
});

/**
 * NewsItemView view component class.
 * @function NewsItemView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const NewsItemView = ({ content, location, intl, token }) => {
  return (
    <Container className="view-wrapper event-view">
      {token ? (
        <MetaInfoToolbar
          content={content}
          location={location}
          token={token}
          history={{}}
        />
      ) : null}
      {content.image && (
        <Segment className="imageboxright documentImage" floated="right">
          <Image
            src={
              content.image['content-type'] === 'image/svg+xml'
                ? flattenToAppURL(content.image.download)
                : flattenToAppURL(content.image.scales.preview.download)
            }
            alt={content.title}
            title={content.title}
          />
        </Segment>
      )}
      <h1 className="documentFirstHeading">{content.title}</h1>
      <div className="metadata">
        <FormattedDate
          date={content.effective}
          format={{
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }}
        />
      </div>
      <SharingGroups content={content} />
      <div className="contentblocks">
        <AllButTitleBlocks content={content} />
      </div>
      <div className="visualClear" />

      {content.relatedItems && content.relatedItems.length > 0 ? (
        <Segment className="relatedcontent unpadded">
          <Header dividing sub>
            {intl.formatMessage(messages.relatedContent)}
          </Header>
          <List>
            {content.relatedItems.map((el, idx) => (
              <List.Item key={idx}>
                <UniversalLink href={el['@id']}>{el.title}</UniversalLink>
              </List.Item>
            ))}
          </List>
        </Segment>
      ) : null}

      <div className="visualClear" />
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
NewsItemView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default injectIntl(NewsItemView);
