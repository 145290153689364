import React, { useEffect } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import cx from 'classnames';
import { Checkbox } from 'semantic-ui-react';
import { getVocabulary } from '@plone/volto/actions';
import { ConditionalLink } from '@plone/volto/components';
import '../breaking.css';

export const messages = defineMessages({
  isSentImmediate: {
    id: 'isSentImmediate',
    defaultMessage: 'is sent immediate',
  },
  isSentDigest: {
    id: 'isSentDigest',
    defaultMessage: 'is sent digest',
  },
});

const ListingSubscriptionsTemplate = ({ items, isEditMode }) => {
  const intl = useIntl();
  moment.locale(intl.locale);
  let now = new Date();
  const dispatch = useDispatch();
  let messagechannels_array = useSelector(
    (state) =>
      state.vocabularies['rohberg.breakingnews.messagechannelsupply']?.items,
  );
  messagechannels_array = messagechannels_array?.sort((a, b) =>
    a.label.localeCompare(b.label),
  );

  useEffect(() => {
    dispatch(
      getVocabulary({
        vocabNameOrURL: 'rohberg.breakingnews.messagechannelsupply',
      }),
    );
  }, [dispatch]);

  return (
    <>
      <div className="channel-options">
        {messagechannels_array?.map((channel) => (
          <div className="channel-options-channel inclined" key={channel.value}>
            <div>
              <span className="label">{channel.label.split('|')[0]}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="listingsubscriptions items">
        {items.map((item) => (
          <div className="listing-item" key={item['@id']}>
            <div className="listing-title">
              <div className="breaking-header">
                <div>
                  <h4
                    className={cx(item.review_state, {
                      expired:
                        item.expires !== null && new Date(item.expires) < now,
                    })}
                  >
                    <ConditionalLink
                      className={cx(item.review_state, {
                        expired:
                          item.expires !== null && new Date(item.expires) < now,
                      })}
                      key={item.id}
                      to={`${item['@id']}/edit`}
                      condition={!isEditMode}
                    >
                      {item.title}
                    </ConditionalLink>
                  </h4>
                </div>
                <div className="channels">
                  {messagechannels_array?.map((channel) => (
                    <div
                      className="distributionmode"
                      key={channel.value}
                      title="read only"
                    >
                      <Checkbox
                        readOnly
                        name={`field-channel_${item['UID']}_${channel.value}_digest`}
                        key="digest"
                        title="digest"
                        className="digest"
                        defaultChecked={item.messagechannels
                          .map((el) => el.token)
                          .includes(channel.value)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="listing-body">
              <div
                className={cx('discreet', {
                  pipelined: new Date(item.sendDate) > now,
                })}
              >
                {moment(item.sendDate).format('L LT')}
                {item.isSentImmediate &&
                  ' | ' + intl.formatMessage(messages.isSentImmediate)}
                {item.isSentDigest &&
                  ' | ' + intl.formatMessage(messages.isSentDigest)}
                {!item.isSentImmediate && !item.isSentDigest && ' | pipelined'}
              </div>
              <div>{item.description}</div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
export default ListingSubscriptionsTemplate;
