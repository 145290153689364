import React from 'react';
import { defineMessages } from 'react-intl';
import { Accordion } from 'semantic-ui-react';
import AnimateHeight from 'react-animate-height';

import config from '@plone/volto/registry';
import StyleWrapper from '@plone/volto/components/manage/Blocks/Block/StyleWrapper';

import {
  getBlocksFieldname,
  getBlocksLayoutFieldname,
  getBaseUrl,
} from '@plone/volto/helpers';

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
});

const Blocks = (props) => {
  const { blocks, content, intl, location } = props;
  const blocksFieldname = getBlocksFieldname(content);
  return blocks.map((block) => {
    const Block =
      config.blocks.blocksConfig[
        content[blocksFieldname]?.[block]?.['@type']
      ]?.['view'] || null;
    return Block !== null ? (
      <StyleWrapper
        key={block}
        {...props}
        id={block}
        block={block}
        data={content[blocksFieldname][block]}
      >
        <Block
          key={block}
          id={block}
          properties={content}
          data={content[blocksFieldname][block]}
          path={getBaseUrl(location?.pathname || '')}
        />
      </StyleWrapper>
    ) : (
      <div key={block}>
        {intl.formatMessage(messages.unknownBlock, {
          block: content[blocksFieldname]?.[block]?.['@type'],
        })}
      </div>
    );
  });
};

/**
 * Zeigt erste zwei Blocks. Restliche Blocks erst auf Klick auf ""weiterlesen"
 */
const AllButTitleBlocksReadmore = (props) => {
  const { content, intl, location, metadata } = props;
  const [activeIndex, setActiveIndex] = React.useState(null);

  function accordionClickHandler() {
    const newIndex = activeIndex === 0 ? -1 : 0;
    setActiveIndex(newIndex);
  }

  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);

  let blocks =
    content[blocksLayoutFieldname]?.items?.filter(
      (block) => content[blocksFieldname]?.[block]?.['@type'] !== 'title',
    ) || [];
  let teaserblocks = blocks.slice(0, 2);
  let furtherblocks = blocks.slice(2, 100);

  const isActive = (index) => {
    return activeIndex === index;
  };

  return (
    <>
      <Blocks
        blocks={teaserblocks}
        content={content}
        intl={intl}
        location={location}
      />
      {furtherblocks.length > 0 ? (
        <Accordion>
          <Accordion.Title
            active={isActive(0)}
            index={0}
            onClick={accordionClickHandler}
          >
            <span className="discreet">weiterlesen…</span>
          </Accordion.Title>
          <AnimateHeight
            animateOpacity
            duration={500}
            height={isActive(0) ? 'auto' : 0}
          >
            <Accordion.Content active={isActive(0)}>
              <Blocks
                blocks={furtherblocks}
                content={content}
                intl={intl}
                location={location}
              />
            </Accordion.Content>
          </AnimateHeight>
        </Accordion>
      ) : null}
    </>
  );
};

export default AllButTitleBlocksReadmore;
