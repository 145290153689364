export const TooltipEditorSchema = {
  title: 'Tooltip',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['tooltip_position', 'tooltip_text'],
    },
  ],
  properties: {
    tooltip_text: {
      title: 'Text',
      type: 'string',
    },
  },
  required: [],
};
