import { defineMessages } from 'react-intl';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import manualSVG from '@plone/volto/icons/binder.svg';
import overviewPageSVG from '@plone/volto/icons/freedom.svg';

import { addStyling } from '@plone/volto/helpers/Extensions/withBlockSchemaEnhancer';
import { composeSchema } from '@plone/volto/helpers';
import { getPreviousNextBlock } from '@plone/volto/helpers';

import {
  HistoryWidget,
  ListingPersonenUndFunktionen,
  ListingExpandable,
  ListingTOC,
  ListingManualInformationstyp,
  ListingManualKomponenten,
  ManualView,
  OverviewPageSearchView,
  PersonView,
  VocabularyDictionaryWidget,
  DefaultListingBlockTemplatePlus,
  SharingView,
  Shortcuts,
  EditBookmarks,
} from './components';

import CustomTextBlockView from '@package/patches/volto-slate/blocks/Text/TextBlockView';

import { Tooltips } from '@rohberg/volto-slate-glossary/components';
import TokenWidget from '@plone/volto/components/manage/Widgets/TokenWidget';

import installAdvancedLinkPlugin from '@rohberg/volto-tweaks-rohberg/editor/plugins/AdvancedLinkPlugin';
import {
  enableBlockRestriction,
  // withRestriction,
} from '@rohberg/volto-tweaks-rohberg/restrictor';

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';

defineMessages({
  sharing_view: {
    id: 'Sharing-View',
    defaultMessage: 'Sharing-Ansicht',
  },
});

export default function applyConfig(config) {
  config.settings.defaultBlockType = 'slate';
  config.settings.matomoSiteId = '1';
  config.settings.matomoUrlBase = 'https://matomo.rohberg.ch/';
  config.settings.supportedLanguages = ['en', 'de'];
  config.settings.defaultLanguage = 'de';
  config.settings.contentIcons.Manual = manualSVG;
  config.settings.contentIcons.OverviewPage = overviewPageSVG;
  // Disable selection of any block and prevent scrolling to a block:
  config.blocks.initialBlocksFocus = null;

  config.settings.appExtras = [
    ...config.settings.appExtras,
    {
      match: '/dokumentation',
      component: Tooltips,
    },
    {
      match: '/inside',
      component: Tooltips,
    },
    {
      match: '/testseiten',
      component: Tooltips,
    },
    {
      match: '/',
      component: Shortcuts,
    },
  ];

  // apiExpanders
  // config.settings.apiExpanders = [
  //   ...config.settings.apiExpanders,
  //   {
  //     match: '/dokumentation',
  //     GET_CONTENT: ['navigation'],
  //     querystring: {
  //       'expand.navigation.depth': 3,
  //     },
  //   },
  // ];

  config.views.layoutViewsNamesMapping = {
    ...config.views.layoutViewsNamesMapping,
    sharing_view: 'Sharing-View',
  };

  // Sentry
  config.settings.sentryOptions = (libraries) => {
    const { CaptureConsole } = libraries['SentryIntegrations'];
    return {
      integrations: [
        new CaptureConsole({
          levels: ['error'],
        }),
      ],
    };
  };

  // Style wrapper
  config.settings.styleClassNameExtenders = [
    ({ block, content, data, classNames }) => {
      let styles = [];
      // eslint-disable-next-line no-unused-vars
      const [previousBlock, nextBlock] = getPreviousNextBlock({
        content,
        block,
      });

      // Given a StyleWrapper defined `Hintergrund` style
      // const previousColor = previousBlock?.styles?.Hintergrund ?? 'transparent';
      const currentColor = data?.styles?.Hintergrund ?? 'transparent';
      const nextColor = nextBlock?.styles?.Hintergrund ?? 'transparent';
      // console.debug('** currentColor, nextColor:', currentColor, nextColor);

      // Inject a class depending if the previous block has the same `Hintergrund`
      // if (currentColor === previousColor) {
      //   styles.push('previous--has--same--Hintergrund');
      // } else if (currentColor !== previousColor) {
      //   styles.push('previous--has--different--Hintergrund');
      // }

      // Inject a class depending if the next block has the same `Hintergrund`
      if (currentColor === nextColor) {
        styles.push('next--has--same--Hintergrund');
      } else if (currentColor !== nextColor) {
        styles.push('next--has--different--Hintergrund');
      }

      return [...classNames, ...styles];
    },
  ];

  // @rohberg/volto-searchkit-block
  config.settings.searchkitblock.trackVoltoMatomo = true;

  // searchkit: override components
  // config.settings.searchkitblock.overriddenComponents = {
  //   'BucketAggregation.element': DropdownMultiBucketAggregationElement,
  // };

  config.settings.bookmarks = {
    ...config.settings.bookmarks,
    bookmarkgroupmapping: {
      anleitung: 'Anleitung',
      'einfuhrung-ubersicht': 'Einführung/Übersicht',
      faq: 'FAQ',
      kurzanleitung: 'Kurzanleitung',
      referenz: 'Referenz',
      'release-note': 'Release-Note',
      tutorial: 'Tutorial',
      'prozess-dokumentation': 'Prozess-Dokumentation',
      '4f3dcd23-81f8-4a8b-8e51-b05661785349': 'Prozess-Dokumentation',
      default_search: 'Suchresultate',
      default_nogroup: 'IGIB-Inside / Allgemein',
    },
    bookmarkgroupfield: 'informationtype',
    filtermapping: {
      facet_fields: {
        '5237dc43-c573-4651-a5b8-cf24bfde13a6': 'Datendrehscheibe',
        allgemeines: 'Allgemeines',
        arbeitsliste: 'Arbeitsliste',
        beb2k: 'BEB2k',
        bew: 'BEW',
        'c2479672-e60d-4919-836e-1a80d8c2aa2c': 'KSW',
        dat: 'DAT',
        'ecm-korrespondenz': 'ECM / Korrespondenz',
        'externe-applikationen': 'Externe Applikationen',
        'funktions-und-druckmenu': 'Funktions- und Druckmenu',
        lau: 'LAU',
        lena: 'LENA',
        personenregister: 'Personenregister',
        qualifikationsverfahren: 'QVFOO',
        qv: 'QV',
        qvpex: 'QVPEX',
        'release-notes': 'Release-Notes',
        'reports-und-berichte': 'Reports und Berichte',
        rew: 'REW',
        stammdaten: 'Stammdaten',
        statistiken: 'Statistiken',
        sti: 'STI',
        web: 'WEB',
        'zentraler-betrieb': 'Zentraler Betrieb',
        '4f3dcd23-81f8-4a8b-8e51-b05661785349': 'Prozess-Dokumentation',
        anleitung: 'Anleitung',
        'einfuhrung-ubersicht': 'Einführung/Übersicht',
        faq: 'FAQ',
        kurzanleitung: 'Kurzanleitung',
        referenz: 'Referenz',
        'release-note': 'Release-Note',
        tutorial: 'Tutorial',
        applikationsverantwortliche: 'Applikationsverantwortliche',
        'fachliche-administratoren': 'Fachliche Administratoren',
        'geschaftsstelle-igib-grif': 'Geschäftsstelle IGIB/GRIF',
        'kompass-benutzer-innen': 'Kompass Benutzer/innen',
        'superuser-innen': 'Superuser/innen',
        ai: 'AI',
        andere: 'Andere',
        ar: 'AR',
        fl: 'FL',
        gr: 'GR',
        igib: 'IGIB',
        lu: 'LU',
        sg: 'SG',
        sh: 'SH',
        so: 'SO',
        tg: 'TG',
        zh: 'ZH',
      },
      search_sections: {
        others: 'Website',
        dokumentation: 'Dokumentation',
        inside: 'IGIB-Inside',
        geologie: 'Geologie',
      },
    },
  };

  config.settings.breakingnews = {
    ...config.settings.breakingnews,
    portal_type_subscribers: ['zhkathauthor'],
    portal_type_content: ['News Item'],
  };

  config.widgets.widget.manualhistory_widget = HistoryWidget;
  // obsolete: config.widgets.type.dict configuration. obsolete with the switch to vocabularyterms
  config.widgets.type.dict = VocabularyDictionaryWidget;
  config.widgets.id.freemanualtags = TokenWidget;

  config.views.layoutViews.sharing_view = SharingView;

  config.views.contentTypesViews.Manual = ManualView;
  config.views.contentTypesViews.OverviewPage = OverviewPageSearchView;
  config.views.contentTypesViews.zhkathauthor = PersonView;

  const addEffectiveAndDescription = ({ schema }) => {
    schema.properties.showEffective = {
      type: 'boolean',
      title: 'Zeige Datum an',
    };
    schema.properties.showDescription = {
      type: 'boolean',
      title: 'Zeige Beschreibung an',
    };
    schema.fieldsets[0].fields.push('showEffective', 'showDescription');
    return schema;
  };
  config.blocks.blocksConfig.listing.schemaEnhancer = composeSchema(
    config.blocks.blocksConfig.listing.schemaEnhancer,
    addEffectiveAndDescription,
  );

  config.blocks.blocksConfig.listing.variations = [
    ...config.blocks.blocksConfig.listing.variations,
    {
      id: 'listingExpandable',
      isDefault: false,
      title: 'Akkordion',
      template: ListingExpandable,
      fullobjects: true,
    },
    {
      id: 'listingTOC',
      isDefault: false,
      title: 'Inhaltsverzeichnis',
      template: ListingTOC,
      fullobjects: true,
    },
    {
      id: 'peopleandpositions',
      isDefault: false,
      title: 'Personen und Funktionen',
      template: ListingPersonenUndFunktionen,
      fullobjects: true,
    },
    {
      id: 'listingManualInformationstyp',
      isDefault: false,
      title: 'Manuals mit Informationstyp',
      template: ListingManualInformationstyp,
    },
    {
      id: 'listingManualKomponenten',
      isDefault: false,
      title: 'Manuals mit Komponenten',
      template: ListingManualKomponenten,
    },
  ].filter((el) => {
    return (
      el.template !== 'DefaultListingBlockTemplate' &&
      el.id !== 'imageGallery' &&
      el.id !== 'summary'
    );
  });

  // Deprecated block
  config.blocks.blocksConfig.listingFullContent = {
    ...config.blocks.blocksConfig.listing,
    id: 'listingFullContent',
    title: 'nimm "Auflistung"',
    mostUsed: false,
    variations: [
      {
        id: 'listingExpandable',
        isDefault: true,
        title: 'Akkordion',
        template: ListingExpandable,
      },
    ],
    fullobjects: true,
  };
  // Replace default variation
  let index_of_default = config.blocks.blocksConfig.listing.variations.findIndex(
    (obj) => obj.id === 'default',
  );
  config.blocks.blocksConfig.listing.variations[index_of_default] = {
    id: 'default',
    isDefault: true,
    title: 'Default',
    template: DefaultListingBlockTemplatePlus,
  };

  config.blocks.blocksConfig.toc.restricted = true;
  config.blocks.blocksConfig.search.restricted = true;
  config.blocks.blocksConfig.video.mostUsed = false;
  config.blocks.blocksConfig.columnsBlock.mostUsed = true;
  config.blocks.blocksConfig.accordion.mostUsed = true;

  config.settings.rohbergVoltoTweaksRohberg = {
    ...(config.settings.rohbergVoltoTweaksRohberg || {}),
    personfieldsVocabulary: 'igib.personfields',
  };
  config.settings.rohbergVoltoTweaksRohberg.enableBlockRestriction = false;

  /**
   * Slate settings
   */

  // glossary
  // config.settings.slateGlossary = {
  //   applyTo: [
  //     {
  //       match: {
  //         path: '/dokumentation',
  //       },
  //       types: ['Document', 'Manual'],
  //     },
  //     {
  //       match: {
  //         path: '/inside',
  //       },
  //       types: ['Document', 'Manual', 'NewsItem', 'Event'],
  //     },
  //     {
  //       match: {
  //         path: '/testseiten',
  //       },
  //       // types: ['Document', 'Manual', 'NewsItem', 'Event'],
  //       types: ['Document'],
  //     },
  //   ],
  // };

  // slate textblock variations (e.g. label block) *********************
  const addBlockLabel = ({ schema }) => {
    schema.properties.blocklabel = {
      title: 'Blocklabel',
      description: 'Markieren Sie den Block mit einem angehefteten Label.',
      type: 'string',
    };
    schema.fieldsets[0].fields.push('blocklabel');
    schema.fieldsets[0].fields = schema.fieldsets[0].fields.filter(
      (el) => el !== 'override_toc',
    );
    return schema;
  };

  // New block style stuff
  // https://6.docs.plone.org/volto/blocks/block-style-wrapper.html#the-styles-field

  const addMyCustomBlockColorStyling = ({ schema, intl }) => {
    addStyling({ schema, intl });

    schema.properties.styles.schema = {
      title: 'Fancy Style',
      block: 'callToAction',
      fieldsets: [
        {
          id: 'default',
          title: 'Default',
          fields: ['Hintergrund'],
        },
      ],
      properties: {
        Hintergrund: {
          title: 'Stil',
          widget: 'color_picker',
          // default: 'blueBox',
          // missing_value: 'grayBox',
          colors: [
            { name: 'greenBox', label: 'Ausserdem' },
            { name: 'blueBox', label: 'Bemerkung' },
            { name: 'grayBox', label: 'Wichtig' },
            { name: 'orangeBox', label: 'Achtung' },
          ],
        },
      },
      required: [],
    };

    return schema;
  };

  config.blocks.blocksConfig.slate.schemaEnhancer = composeSchema(
    config.blocks.blocksConfig.slate.schemaEnhancer,
    addBlockLabel,
    addMyCustomBlockColorStyling,
  );
  config.blocks.blocksConfig.listing.schemaEnhancer = composeSchema(
    config.blocks.blocksConfig.listing.schemaEnhancer,
    addMyCustomBlockColorStyling,
  );
  config.blocks.blocksConfig.accordion.schemaEnhancer = composeSchema(
    config.blocks.blocksConfig.accordion.schemaEnhancer,
    addMyCustomBlockColorStyling,
  );
  config.blocks.blocksConfig.columnsBlock.schemaEnhancer = composeSchema(
    config.blocks.blocksConfig.columnsBlock.schemaEnhancer,
    addMyCustomBlockColorStyling,
  );

  // volto-block-style ************************
  config.settings.pluggableStyles = [
    ...(config.settings.pluggableStyles || []),
    {
      id: 'greenBox',
      title: 'Ausserdem',
      cssClass: 'green-block-style-box',
    },
    {
      id: 'blueBox',
      title: 'Bemerkung',
      cssClass: 'blue-block-style-box',
    },
    {
      id: 'grayBox',
      title: 'Wichtig',
      cssClass: 'gray-block-style-box',
    },
    {
      id: 'orangeBox',
      title: 'Achtung',
      cssClass: 'orange-block-style-box',
    },
  ];

  // Style menu in editor toolbar
  config.settings.slate.styleMenu.inlineStyles = [
    {
      cssClass: 'inline-marker-warning',
      label: 'Warning',
    },
    {
      cssClass: 'inline-marker-error',
      label: 'Error',
    },
    {
      cssClass: 'inline-marker-small',
      label: 'Klein',
    },
  ];

  // Revive OLD Slate editor LINK Plugin (type 'a') with schema
  config = installAdvancedLinkPlugin(config);
  // Remove toolbar button of new link and replace by button of old schema based link
  config.settings.slate.toolbarButtons = config.settings.slate.toolbarButtons.filter(
    (el) => el !== 'a',
  );
  config.settings.slate.toolbarButtons[
    config.settings.slate.toolbarButtons.indexOf('link')
  ] = 'a';

  // Remove subsequent separators
  config.settings.slate.toolbarButtons = config.settings.slate.toolbarButtons.filter(
    (a, i) => config.settings.slate.toolbarButtons[i - 1] !== a,
  );

  config.settings.slate.scrollIntoView = false;

  config.blocks.blocksConfig.slate.view = CustomTextBlockView;

  config.settings.slate.hotkeys = {
    'MOD+b': { format: 'fett', type: 'inline' },
    'MOD+i': { format: 'kursiv', type: 'inline' },
    'MOD+u': { format: 'unterstrichen', type: 'inline' },
    'MOD+s': { format: 'durchgestrichen', type: 'inline' },
    // 'MOD+`': { format: 'code', type: 'inline' },
    // 'MOD+z': { format: 'undo', type: 'inline' },
    // 'MOD+y': { format: 'redo', type: 'inline' },
    // TODO: more hotkeys, including from plugins!
  };

  // End of slate settings *******************************

  // Enable sharing of blocks (default blocks and these of add-ons)
  enableBlockRestriction(config);

  config.addonRoutes = [
    ...config.addonRoutes,
    {
      path: '/controlpanel/edit-bookmarks',
      component: EditBookmarks,
    },
  ];

  // On select text (slate) block: select block settings in sidebar
  config.blocks.blocksConfig.slate.sidebarTab = 1;

  return config;
}
