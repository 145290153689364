/**
 * OverviewPage view component.
 * @module components/Views/OverviewPageSearchView
 */

import React from 'react';

import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import { AllButTitleBlocks, MetaInfoToolbar } from '~/components';

/**
 * Component to display the OverviewPageSearchView view.
 * @function OverviewPageSearchView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const OverviewPageSearchView = ({ content, location, token }) => {
  const show_metainfotoolbar = token && location.pathname.startsWith('/suche');
  return (
    <div id="page-document" className="ui container">
      {show_metainfotoolbar ? (
        <MetaInfoToolbar
          content={content}
          location={location}
          token={token}
          history={{}}
        />
      ) : null}
      <h1 className="documentFirstHeading overviewpagesearchview">
        {content.title}
      </h1>
      <AllButTitleBlocks content={content} />
    </div>
  );
};

export default withRouter(injectIntl(OverviewPageSearchView));
