/**
 * Add your action types here.
 * @module constants/ActionTypes
 * @example
 * export const UPDATE_CONTENT = 'UPDATE_CONTENT';
 */

export const SET_OVERVIEW_MODUS = 'SET_OVERVIEW_MODUS';
export const EXPAND_DOCUMENTATIONSIDEBAR = 'EXPAND_DOCUMENTATIONSIDEBAR';
export const GET_BOOKMARKS_ADMIN = 'GET_BOOKMARKS_ADMIN';
