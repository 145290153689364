/**
 * View for user to edit his subscriptions
 */
import React, { useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { get, isEqual } from 'lodash';
import { toast } from 'react-toastify';
import { Checkbox, Container, Grid } from 'semantic-ui-react';
import {
  getUser,
  getVocabulary,
  updateContent,
  searchContent,
} from '@plone/volto/actions';
import { Toast } from '@plone/volto/components';
import config from '@plone/volto/registry';
import './breaking.css';

export const messages = defineMessages({
  editsubscriptionheader: {
    id: 'editsubscriptionheader',
    defaultMessage: 'Your Subscriptions',
  },
  success: {
    id: 'Success',
    defaultMessage: 'Success',
  },
  subscriptionsUpdated: {
    id: 'subscriptionsUpdated',
    defaultMessage: 'Subscriptions are updated.',
  },
  immediate: {
    id: 'immediate',
    defaultMessage: 'immediate',
  },
  digest: {
    id: 'digest',
    defaultMessage: 'digest',
  },
  distributionmodehelp: {
    id: 'distributionmodehelp',
    defaultMessage: 'immediate / digest',
  },
});

const EditSubscription = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.userSession?.token);
  let messagechannels_array = useSelector((state) =>
    state.vocabularies[
      'rohberg.breakingnews.messagechannelsupply'
    ]?.items?.sort((el) => el.label),
  );
  messagechannels_array = messagechannels_array?.sort((a, b) =>
    a.label.localeCompare(b.label),
  );
  const currentuser = useSelector((state) => state.users?.user);
  const users = useSelector(
    (state) => state.search.subrequests.membraneuserwithtoken?.items,
  );

  useEffect(() => {
    dispatch(
      getVocabulary({
        vocabNameOrURL: 'rohberg.breakingnews.messagechannelsupply',
      }),
    );
  }, [dispatch]);

  const fetchData = useCallback(() => {
    let userid = token ? jwtDecode(token).sub : '';
    dispatch(getUser(userid));
    let query_users = {
      portal_type: config.settings.breakingnews.portal_type_subscribers,
      UID: currentuser.id,
      b_size: 3,
      fullobjects: true,
    };
    dispatch(searchContent('/', query_users, 'membraneuserwithtoken'));
  }, [dispatch, token, currentuser]);

  useEffect(() => {
    let userid = token ? jwtDecode(token).sub : '';
    dispatch(getUser(userid));
    let query_users = {
      portal_type: config.settings.breakingnews.portal_type_subscribers,
      UID: currentuser.id,
      b_size: 3,
      fullobjects: true,
    };
    dispatch(searchContent('/', query_users, 'membraneuserwithtoken'));
  }, [dispatch, token, currentuser]);

  const onSelectChannelHandler = async (
    channelvalue,
    distributionmode,
    checked,
  ) => {
    // update user subscribedchannels
    let newchannels = users[0].subscribedchannels;
    if (Array.isArray(newchannels)) {
      newchannels = {}; // legacy clean up
    }
    // It is important not to save {immediate: False} or {digest: False}
    // to avoid issues concerning filtering by channel and subscriptions
    if (newchannels[channelvalue]) {
      if (checked) {
        newchannels[channelvalue][distributionmode] = checked;
      } else {
        delete newchannels[channelvalue][distributionmode];
        if (isEqual(newchannels[channelvalue], {})) {
          delete newchannels[channelvalue];
        }
      }
    } else {
      if (checked) {
        newchannels[channelvalue] = {};
        newchannels[channelvalue][distributionmode] = checked;
      } else {
        if (isEqual(newchannels[channelvalue], {})) {
          delete newchannels[channelvalue];
        }
      }
    }
    let userData = {
      subscribedchannels: newchannels,
    };

    const lock_token = currentuser.lock?.token;
    const headers = lock_token ? { 'Lock-Token': lock_token } : {};
    await dispatch(updateContent(users[0]['@id'], userData, headers));

    toast.success(
      <Toast
        success
        title={intl.formatMessage(messages.success)}
        content={intl.formatMessage(messages.subscriptionsUpdated, {
          title: intl.formatMessage(messages.editsubscriptionheader),
        })}
      />,
    );
    fetchData();
  };
  return token && messagechannels_array?.length ? (
    <div>
      <Container>
        <h2>{intl.formatMessage(messages.editsubscriptionheader)}</h2>

        <p>
          <FormattedMessage
            id="editsubscriptionprolog"
            defaultMessage="Edit your subscriptions according to your needs."
          />
        </p>
        {users?.length > 0
          ? messagechannels_array
              ?.sort((a, b) => b.label < a.label)
              .map((channel) => (
                <div className="channel-row" key={channel.value}>
                  <Grid>
                    <Grid.Column width={4}>
                      {channel.label.split('|')[0]}
                    </Grid.Column>
                    <Grid.Column key={`${channel.value}-immediate`} width={4}>
                      {channel.label.split('|')[1] === '1' && (
                        <Checkbox
                          toggle
                          name={`field-channel_${channel.value}_immediate`}
                          id={`field-channel_${channel.value}_immediate`}
                          label={intl.formatMessage(messages.immediate)}
                          defaultChecked={
                            get(
                              get(
                                users[0].subscribedchannels,
                                channel.value,
                                {},
                              ),
                              'immediate',
                              false,
                            ) || false
                          }
                          onChange={(event, { checked }) => {
                            onSelectChannelHandler(
                              channel.value,
                              'immediate',
                              checked,
                            );
                          }}
                        />
                      )}
                    </Grid.Column>
                    <Grid.Column key={`${channel.value}-digest`} width={4}>
                      {channel.label.split('|')[2] === '1' && (
                        <Checkbox
                          toggle
                          name={`field-channel_${channel.value}digest`}
                          id={`field-channel_${channel.value}_digest`}
                          label={intl.formatMessage(messages.digest)}
                          defaultChecked={
                            get(
                              get(
                                users[0].subscribedchannels,
                                channel.value,
                                {},
                              ),
                              'digest',
                              false,
                            ) || false
                          }
                          onChange={(event, { checked }) => {
                            onSelectChannelHandler(
                              channel.value,
                              'digest',
                              checked,
                            );
                          }}
                        />
                      )}
                    </Grid.Column>
                  </Grid>
                </div>
              ))
          : null}
      </Container>
    </div>
  ) : null;
};

export default EditSubscription;
