/**
 * changes
 * if type File: link to download url
 */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

import { isInternalURL } from '@plone/volto/helpers/Url/Url';

const DefaultTemplate = ({
  items,
  linkMore,
  isEditMode,
  showEffective,
  showDescription,
}) => {
  const location = useLocation();

  let link = null;
  let href = linkMore?.href || '';

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkMore?.title || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <a href={href}>{linkMore?.title || href}</a>;
  }

  return (
    <>
      <div className="items">
        {items.map((item) => {
          return (
            <div className="listing-item" key={item['@id']}>
              {item['@type'] === 'File' ? (
                <ConditionalLink
                  to={item['@id'] + '/@@download/file'}
                  condition={!isEditMode}
                >
                  <div className="listing-body">
                    <h4 className={item.review_state}>
                      {item.title ? item.title : item.id}
                    </h4>
                    <p>{item.description}</p>
                  </div>
                </ConditionalLink>
              ) : (
                <ConditionalLink
                  item={item}
                  condition={!isEditMode}
                  className={
                    item['@id'] === location.pathname
                      ? 'pagelink active'
                      : 'pagelink'
                  }
                >
                  <div className="listing-body">
                    <h4 className={item.review_state}>
                      {item.title ? item.title : item.id}
                    </h4>
                    {showEffective ? (
                      <>
                        {item.effective && !item.start && (
                          <p className="listingdate discreet">
                            {moment(item.effective).format('DD.MM.YYYY')}
                          </p>
                        )}
                        {item.start && (
                          <p className="listingdate discreet">
                            {moment(item.start).format('DD.MM.YYYY')}
                          </p>
                        )}
                      </>
                    ) : null}
                    {showDescription ? (
                      <p className="description">{item.description}</p>
                    ) : null}
                  </div>
                </ConditionalLink>
              )}
            </div>
          );
        })}
      </div>

      {link && <div className="footer">{link}</div>}
    </>
  );
};
DefaultTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default DefaultTemplate;
