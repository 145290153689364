/**
 * NewschannelsWidget
 * Widget for controlpanel, managing available channels
 */
import React from 'react';
import { defineMessages } from 'react-intl';

import ObjectListWidget from '@plone/volto/components/manage/Widgets/ObjectListWidget';

const messages = defineMessages({
  channelitemtitle: {
    id: 'Channel Item',
    defaultMessage: 'Channel',
  },
  addChannelItem: {
    id: 'Add channel item',
    defaultMessage: 'Add channel',
  },
  channel: {
    id: 'channel value',
    defaultMessage: 'Channel',
  },
  immediate: {
    id: 'send immediate',
    defaultMessage: 'Can be send immediate',
  },
  digest: {
    id: 'send as digest',
    defaultMessage: 'Can be send as digest',
  },
});

const ItemSchema = ({ intl }) => {
  return {
    title: intl.formatMessage(messages.channelitemtitle),
    addMessage: intl.formatMessage(messages.addChannelItem),
    properties: {
      channel: {
        title: intl.formatMessage(messages.channel),
      },
      immediate: {
        title: intl.formatMessage(messages.immediate),
        type: 'boolean',
      },
      digest: {
        title: intl.formatMessage(messages.digest),
        type: 'boolean',
      },
    },
    fieldsets: [
      {
        id: 'default',
        title: 'foo',
        fields: ['channel', 'immediate', 'digest'],
      },
    ],
    required: [],
  };
};

// TODO defaultData
const MyWidget = (props) => {
  return (
    <ObjectListWidget
      schema={ItemSchema}
      {...props}
      value={props.value?.items || props.default?.items || []}
      onChange={(id, value) => props.onChange(id, { items: value })}
    />
  );
};

export default MyWidget;
