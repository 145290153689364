import { ListingSubscriber, ListingSubscriptions } from './components/listing';
import TextlinesWidget from './components/widgets/TextlinesWidget';
import NewschannelsWidget from './components/widgets/NewschannelsWidget';
import EditSubscription from './components/EditSubscription';
import ManageSubscriptions from './components/ManageSubscriptions';

const applyConfig = (config) => {
  config.settings.breakingnews = {
    portal_type_subscribers: ['dexterity.membrane.member'],
    portal_type_content: ['News Item'],
  };

  config.settings.breakingnews.search_and_manage_variations = [
    {
      id: 'subscriber',
      isDefault: true,
      title: 'Subscribers',
      template: ListingSubscriber,
      fullobjects: true,
    },
    {
      id: 'subscriptions',
      isDefault: true,
      title: 'Subscriptions',
      template: ListingSubscriptions,
      fullobjects: true,
    },
  ];

  config.addonRoutes = [
    ...config.addonRoutes,
    {
      path: '/edit-breakingnews-subscriptions',
      component: EditSubscription,
    },
    {
      path: '/controlpanel/manage-breakingnews-subscriptions',
      component: ManageSubscriptions,
    },
  ];

  // Add manage view to controlpanel
  config.settings.controlpanels = [
    ...(config.settings.controlpanels || []),
    {
      '@id': '/manage-breakingnews-subscriptions',
      group: 'Add-on Configuration',
      title: 'Breaking News Manage Subscriptions',
    },
  ];

  config.widgets.widget.textlineswidget = TextlinesWidget;
  config.widgets.widget.newschannels_widget = NewschannelsWidget;

  return config;
};

export default applyConfig;
