import React from 'react';

// import { groupBy } from 'lodash';
import { uniq } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { searchContent } from '@plone/volto/actions';
import { AllButTitleBlocks } from '~/components';

const DocumentationNavigation = ({ kompasscomponents = [] }) => {
  const dispatch = useDispatch();

  const navigationpages = useSelector(
    (state) => state.search.subrequests.navigationpages?.items,
  );

  let navigationpages_by_kompasscomponent = {};
  if (navigationpages?.length > 0) {
    navigationpages.forEach((element) => {
      if (element.kompassmodule) {
        element.kompassmodule.forEach((el_module) => {
          if (navigationpages_by_kompasscomponent[el_module.token]) {
            navigationpages_by_kompasscomponent[el_module.token] = [
              ...navigationpages_by_kompasscomponent[el_module.token],
              element,
            ];
          } else {
            navigationpages_by_kompasscomponent[el_module.token] = [element];
          }
        });
      }
    });
  }

  let relevant_navigationpages = [];
  if (kompasscomponents) {
    kompasscomponents.forEach((element) => {
      if (navigationpages_by_kompasscomponent[element.token]) {
        relevant_navigationpages.push(
          ...navigationpages_by_kompasscomponent[element.token],
        );
      }
    });
  }

  // Fallback navigation page: The one with id first in alphabet
  if (relevant_navigationpages.length === 0) {
    if (navigationpages && navigationpages.length > 0) {
      let firstnavipage = JSON.parse(JSON.stringify(navigationpages[0]));
      relevant_navigationpages.push(firstnavipage);
    }
  }

  // TODO replace uniq with cheaper function
  relevant_navigationpages = uniq(relevant_navigationpages);

  React.useEffect(() => {
    dispatch(
      searchContent(
        '',
        {
          portal_type: ['Module Navigation Page'],
          review_state: ['internally_published'],
          b_size: 100,
          fullobjects: true,
        },
        'navigationpages',
      ),
    );
  }, [dispatch]);

  return relevant_navigationpages.length > 0 ? (
    <div className="navigationpages">
      {/* <h1 className="documentFirstHeading">Verwandte Themen</h1> */}
      {/* <div className="documentFirstHeadingBorder"> </div> */}
      {relevant_navigationpages.map((el_navigationpage) => (
        <div className="navigationpage" key={el_navigationpage.UID}>
          {/* {console.debug(
            'el_navigationpage.kompassmodule?.pop()',
            el_navigationpage.kompassmodule?.pop(),
          )} */}
          <h1 title={el_navigationpage['@id']}>
            Verwandte Themen{' '}
            {el_navigationpage.kompassmodule.length > 0
              ? el_navigationpage.kompassmodule[0].title
              : ''}
          </h1>
          <div className="documentFirstHeadingBorder"> </div>
          <AllButTitleBlocks
            content={el_navigationpage}
            location={{ pathname: '/' }}
          />
        </div>
      ))}
    </div>
  ) : (
    <div className="navigationpages">
      <p className="navigationpage">
        Bitte Navigationsseite für diese KOMPASS-Komponente erstellen.
      </p>
    </div>
  );
};

export default DocumentationNavigation;
