import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { find, get, isEqual } from 'lodash';
import { toast } from 'react-toastify';
import { Checkbox } from 'semantic-ui-react';
import { getVocabulary, updateContent } from '@plone/volto/actions';
import { ConditionalLink, Toast } from '@plone/volto/components';
// import { userHasRoles } from '@plone/volto/helpers'; // Would be awesome to use userHasRoles, but here is no user.
import { messages } from '../EditSubscription';
import '../breaking.css';

const ListingSubscriberTemplate = ({ items, isEditMode = false }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  // Check if logged in user is site administrator
  let useractions = useSelector((state) => state.actions.actions.user);
  let canManageSubscribers = find(useractions, { id: 'plone_setup' })
    ? true
    : false;
  items = canManageSubscribers ? items : [];

  let messagechannels_array = useSelector(
    (state) =>
      state.vocabularies['rohberg.breakingnews.messagechannelsupply']?.items,
  );
  messagechannels_array = messagechannels_array?.sort((a, b) =>
    a.label.localeCompare(b.label),
  );

  useEffect(() => {
    dispatch(
      getVocabulary({
        vocabNameOrURL: 'rohberg.breakingnews.messagechannelsupply',
      }),
    );
  }, [dispatch]);

  const onSelectChannelHandler = (
    item,
    channelvalue,
    distributionmode,
    checked,
  ) => {
    // update user subscribedchannels
    let newchannels = item.subscribedchannels;
    if (Array.isArray(newchannels)) {
      newchannels = {}; // legacy clean up
    }
    // It is important not to save {immediate: False} or {digest: False}
    // to avoid issues concerning filtering by channel and subscriptions
    if (newchannels[channelvalue]) {
      if (checked) {
        newchannels[channelvalue][distributionmode] = checked;
      } else {
        delete newchannels[channelvalue][distributionmode];
        if (isEqual(newchannels[channelvalue], {})) {
          delete newchannels[channelvalue];
        }
      }
    } else {
      if (checked) {
        newchannels[channelvalue] = {};
        newchannels[channelvalue][distributionmode] = checked;
      } else {
        if (isEqual(newchannels[channelvalue], {})) {
          delete newchannels[channelvalue];
        }
      }
    }
    let userData = {
      // subscribedchannels: ['releasenotes'],
      subscribedchannels: newchannels,
    };

    const lock_token = item.lock?.token;
    const headers = lock_token ? { 'Lock-Token': lock_token } : {};
    dispatch(updateContent(item['@id'], userData, headers));

    toast.success(
      <Toast
        success
        title={intl.formatMessage(messages.success)}
        content={intl.formatMessage(messages.subscriptionsUpdated, {
          title: intl.formatMessage(messages.editsubscriptionheader),
        })}
      />,
    );
  };

  return (
    <>
      <div className="channel-options">
        {messagechannels_array?.map((channel) => (
          <div className="channel-options-channel inclined" key={channel.value}>
            <div>
              <span className="label">{channel.label.split('|')[0]}</span>
            </div>
          </div>
        ))}
      </div>

      <div className="listingsubscriber items">
        {items.map((item) => (
          <div className="listing-item" key={item['@id']}>
            <div className="listing-title">
              <div className="breaking-header">
                <div>
                  <h4 className={item.review_state}>
                    <ConditionalLink
                      className={item.review_state}
                      key={item.id}
                      to={`${item['@id']}/edit`}
                      title={item.companyposition}
                      condition={!isEditMode}
                    >
                      {item.first_name ? item.first_name + ' ' : ''}
                      {item.last_name
                        ? item.last_name
                        : item.title
                        ? item.title
                        : item.id}
                    </ConditionalLink>
                  </h4>
                </div>
                <div className="channels">
                  {messagechannels_array?.map((channel) => (
                    <div
                      className="distributionmode"
                      key={channel.value}
                      title={intl.formatMessage(
                        messages.distributionmodehelp,
                      )}
                    >
                      {channel.label.split('|')[1] === '1' && (
                        <Checkbox
                          name={`field-channel_${item['UID']}_${channel.value}_immediate`}
                          key="immediate"
                          title="immediate"
                          className="immediate"
                          defaultChecked={
                            get(
                              get(item.subscribedchannels, channel.value, {}),
                              'immediate',
                              false,
                            ) || false
                          }
                          onChange={(event, { checked }) => {
                            onSelectChannelHandler(
                              item,
                              channel.value,
                              'immediate',
                              checked,
                            );
                          }}
                        />
                      )}
                      {channel.label.split('|')[2] === '1' && (
                        <Checkbox
                          name={`field-channel_${item['UID']}_${channel.value}_digest`}
                          key="digest"
                          title="digest"
                          className="digest"
                          defaultChecked={
                            get(
                              get(item.subscribedchannels, channel.value, {}),
                              'digest',
                              false,
                            ) || false
                          }
                          onChange={(event, { checked }) => {
                            onSelectChannelHandler(
                              item,
                              channel.value,
                              'digest',
                              checked,
                            );
                          }}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
export default ListingSubscriberTemplate;
