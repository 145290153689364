import { Segment, List } from 'semantic-ui-react';
import config from '@plone/volto/registry';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const ShortcutListing = (props) => {
  const hotkeys = config.settings?.slate?.hotkeys;
  return (
    <div>
      <header className="header pulled">
        <h2>Editor shortcuts</h2>
      </header>

      <Segment>
        <div>
          <FormattedMessage
            id="Type a slash (/) to select a block type."
            defaultMessage="Type a slash (/) to select a block type."
          />
        </div>
        <List>
          {Object.entries(hotkeys || {}).map(([shortcut, { format, type }]) => (
            <List.Item key={shortcut}>{`${shortcut}: ${format}`}</List.Item>
          ))}
        </List>
        <div>
          <FormattedMessage
            id="On Windows, the MOD key is Ctrl, on Mac OS X it's Cmd."
            defaultMessage="On Windows, the MOD key is Ctrl, on Mac OS X it's Cmd."
          />
        </div>
      </Segment>
    </div>
  );
};

export default ShortcutListing;
