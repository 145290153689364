/**
 * Summary view component.
 * @module components/theme/View/SummaryView
 * changes:
 * - date: effective and event start
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import moment from 'moment';
import { endsWith } from 'lodash';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { searchContent } from '@plone/volto/actions';

/**
 * Summary view component class.
 * @function SummaryView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const SummaryView = ({ content }) => {
  const dispatch = useDispatch();

  const intl = useIntl();
  moment.locale(intl.locale);
  moment.defaultFormat = 'DD.MM.YYYY HH:mm';
  const pathname = useSelector((state) => state.router.location.pathname);

  React.useEffect(() => {
    dispatch(
      searchContent(pathname, {
        metadata_fields: ['start', 'effective'],
        'path.depth': 1,
      }),
    );
  }, [dispatch, pathname]);

  const foldercontent = useSelector((state) => state.search.items).filter(
    (item) => !endsWith(content['@id'], item['@id']),
  );
  return (
    <Container className="view-wrapper">
      <article id="content">
        <header>
          <h1 className="documentFirstHeading">{content.title}</h1>
          {content.description && (
            <p className="documentDescription">{content.description}</p>
          )}
        </header>
        <section id="content-core">
          {foldercontent.map((item) => (
            <article key={item.url}>
              <Link to={item['@id']} title={item['@type']}>
                <h2>{item.title}</h2>
              </Link>
              {/* {item.start && (
                <p className="eventstart">
                  {moment(item.start).format('DD.MM.YYYY HH:mm')}
                </p>
              )}
              {item.effective && (
                <p className="eventstart">
                  {moment(item.effective).format('DD.MM.YYYY HH:mm')}
                </p>
              )} */}
              {item.effective && !item.start && (
                <p className="listingdate discreet">
                  {moment(item.effective).format('DD.MM.YYYY')}
                </p>
              )}
              {item.start && (
                <p className="listingdate discreet">
                  {moment(item.start).format('DD.MM.YYYY')}
                </p>
              )}
              {item.description && <p>{item.description}</p>}
            </article>
          ))}
        </section>
      </article>
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
SummaryView.propTypes = {
  /**
   * Content of the object
   */
  content: PropTypes.shape({
    /**
     * Title of the object
     */
    title: PropTypes.string,
    /**
     * Description of the object
     */
    description: PropTypes.string,
    /**
     * Child items of the object
     */
    items: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * Title of the item
         */
        title: PropTypes.string,
        /**
         * Description of the item
         */
        description: PropTypes.string,
        /**
         * Url of the item
         */
        url: PropTypes.string,
        /**
         * Image of the item
         */
        image: PropTypes.object,
        /**
         * Image caption of the item
         */
        image_caption: PropTypes.string,
        /**
         * Type of the item
         */
        '@type': PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default SummaryView;
