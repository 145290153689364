/**
 * TitleBrowser
 * SelectWidget with titles of current page
 */
import React from 'react';
import { useSelector } from 'react-redux';

import SelectWidget from '@plone/volto/components/manage/Widgets/SelectWidget';
import {
  getBlocksFieldname,
  getBlocksLayoutFieldname,
} from '@plone/volto/helpers';
import config from '@plone/volto/registry';

const MyWidget = (props) => {
  const properties = useSelector((state) => state.content.data);

  const blocksFieldname = getBlocksFieldname(properties);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(properties);
  const levels = [1, 2, 3, 4, 5, 6];
  const tocEntries = React.useMemo(() => {
    let entries = [];

    properties[blocksLayoutFieldname].items.forEach((id) => {
      const block = properties[blocksFieldname][id];
      if (typeof block === 'undefined') {
        return null;
      }
      if (!config.blocks.blocksConfig[block['@type']]?.tocEntry) return null;
      const entry = config.blocks.blocksConfig[block['@type']]?.tocEntry(
        block,
        { levels }, // data, // For what is data used in TOC block?
      );
      if (entry) {
        const level = entry[0];
        const title = entry[1];
        if (!level || !levels.includes(level)) return;
        entries.push([id, title]);
      }
    });

    return entries;
  }, [levels, properties, blocksFieldname, blocksLayoutFieldname]);

  return (
    <SelectWidget
      className="select-title"
      choices={tocEntries}
      value={props.value?.id}
      onChange={(id, value) => props.onChange(id, { id: value })}
    />
  );
};

export default MyWidget;
