/**
 * Login container.
 * @module components/theme/Login/Login
 */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Helmet } from '@plone/volto/helpers';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Container } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import qs from 'query-string';
import { withRouter } from 'react-router-dom';

import { getNavigation, login } from '@plone/volto/actions';
import { toast } from 'react-toastify';
import { Form, Toast } from '@plone/volto/components';

import config from '@plone/volto/registry';

const messages = defineMessages({
  login: {
    id: 'Log in',
    defaultMessage: 'Log in',
  },
  loginName: {
    id: 'Login Name',
    defaultMessage: 'Login Name',
  },
  Login: {
    id: 'Login',
    defaultMessage: 'Login',
  },
  Register: {
    id: 'Register',
    defaultMessage: 'Register',
  },
  registerInfo: {
    id: 'Register Info',
    defaultMessage:
      "You can register.</br>Please <a href='/contact-form'>contact</a> us.",
  },
  password: {
    id: 'Password',
    defaultMessage: 'Password',
  },
  cancel: {
    id: 'Cancel',
    defaultMessage: 'Cancel',
  },
  error: {
    id: 'Error',
    defaultMessage: 'Error',
  },
  loginFailed: {
    id: 'Login Failed',
    defaultMessage: 'Login Failed',
  },
  loginFailedContent: {
    id:
      'Both email address and password are case sensitive, check that caps lock is not enabled.',
    defaultMessage:
      'Both email address and password are case sensitive, check that caps lock is not enabled.',
  },
  register: {
    id: 'Register',
    defaultMessage: 'Register',
  },
  forgotPasswordTitle: {
    id: 'forgotPasswordTitle',
    defaultMessage: 'Forgot your password or email?',
  },
  forgotPassword: {
    id: 'box_forgot_password_option',
    defaultMessage: 'Forgot your password?',
  },
  default: {
    id: 'Default',
    defaultMessage: 'Default',
  },
});

/**
 * Login class.
 * @class Login
 * @extends Component
 */
class Login extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    login: PropTypes.func.isRequired,
    token: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
    returnUrl: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.string,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    token: null,
    returnUrl: null,
    loading: null,
    error: null,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.token) {
      this.props.history.push(this.props.returnUrl || '/');
      if (toast.isActive('loginFailed')) {
        toast.dismiss('loginFailed');
      }
    }
    if (nextProps.error) {
      if (!toast.isActive('loginFailed')) {
        toast.error(
          <Toast
            error
            title={this.props.intl.formatMessage(messages.loginFailed)}
            content={this.props.intl.formatMessage(messages.loginFailedContent)}
          />,
          { autoClose: true, toastId: 'loginFailed' },
        );
      }
    }
  }

  UNSAFE_componentWillMount() {
    if (config.settings.isMultilingual) {
      this.props.getNavigation(`/${this.props.lang}`, config.settings.navDepth);
    } else {
      this.props.getNavigation('/', config.settings.navDepth);
    }
  }

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.setState({ isClient: true });
    // TODO check if scrolling make sense for main use cases
    if (typeof window !== 'undefined') {
      // window.scrollTo(0, 0);
      const el = document.querySelector('.documentFirstHeading');
      el.scrollIntoView();
    }
  }

  componentWillUnmount() {
    if (config.settings.isMultilingual) {
      this.props.getNavigation(`/${this.props.lang}`, config.settings.navDepth);
    } else {
      this.props.getNavigation('/', config.settings.navDepth);
    }

    if (toast.isActive('loginFailed')) {
      toast.dismiss('loginFailed');
    }
  }

  /**
   * On submit handler
   * @method oonSubmit
   * @param {Object} event Event object.
   * @returns {undefined}
   */
  onSubmit(data) {
    this.props.login(data.login, data.password);
  }

  /**
   * Cancel handler
   * @method onCancel
   * @returns {undefined}
   */
  onCancel() {
    this.props.history.goBack();
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <div id="view">
        <Helmet title={this.props.intl.formatMessage(messages.Login)} />
        <Container id="page-login">
          <h1 className="documentFirstHeading">
            <FormattedMessage id="Log In" defaultMessage="Login" />
          </h1>
          <Form
            onSubmit={this.onSubmit}
            onCancel={this.onCancel}
            schema={{
              fieldsets: [
                {
                  id: 'default',
                  title: this.props.intl.formatMessage(messages.default),
                  fields: ['login', 'password'],
                },
              ],
              properties: {
                login: {
                  type: 'string',
                  title: this.props.intl.formatMessage(messages.loginName),
                  placeholder: this.props.intl.formatMessage(
                    messages.loginName,
                  ),
                  focus: true,
                },
                password: {
                  title: this.props.intl.formatMessage(messages.password),
                  placeholder: this.props.intl.formatMessage(messages.password),
                  type: 'string',
                  widget: 'password',
                },
              },
              submitLabel: this.props.intl.formatMessage(messages.login),
              required: ['login', 'password'],
            }}
          />

          <h2>
            <FormattedMessage
              id="forgotPasswordTitle"
              defaultMessage="Forgot your password or email?"
            />
          </h2>
          <p className="SOShelp">
            <Link to="/passwordreset">
              {this.props.intl.formatMessage(messages.forgotPassword)}
            </Link>
          </p>

          <h2>
            <FormattedMessage id="Register" defaultMessage="Register" />
          </h2>
          <p
            dangerouslySetInnerHTML={{
              __html: this.props.intl.formatMessage(messages.registerInfo),
            }}
          />
        </Container>
      </div>
    );
  }
}

export default compose(
  withRouter,
  injectIntl,
  connect(
    (state, props) => ({
      lang: state.intl.locale,
      error: state.userSession.login.error,
      loading: state.userSession.login.loading,
      token: state.userSession.token,
      returnUrl:
        qs.parse(props.location.search).return_url ||
        props.location.pathname
          .replace(/\/login$/, '')
          .replace(/\/logout$/, '') ||
        '/',
    }),
    { login, getNavigation },
  ),
)(Login);
