import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { getVocabulary } from '@plone/volto/actions';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import { vocabularyArrayToDict } from '@package/helpers';

const ListingBlockTemplate = ({ items, linkMore, isEditMode }) => {
  const dispatch = useDispatch();
  const informationtypevocabulary_array = useSelector(
    (state) => state.vocabularies['igib.igibmanualinformationtype']?.items,
  );
  const kompasscomponentvocabulary_array = useSelector(
    (state) => state.vocabularies['igib.igibmanualkompasscomponent']?.items,
  );

  React.useEffect(() => {
    dispatch(
      getVocabulary({ vocabNameOrURL: 'igib.igibmanualkompasscomponent' }),
    );
    dispatch(
      getVocabulary({ vocabNameOrURL: 'igib.igibmanualinformationtype' }),
    );
  }, [dispatch]);

  const informationtypevocabulary = vocabularyArrayToDict(
    informationtypevocabulary_array,
    'value',
    'label',
  );
  const kompasscomponentvocabulary = vocabularyArrayToDict(
    kompasscomponentvocabulary_array,
    'value',
    'label',
  );

  return (
    <>
      <div className="items">
        {items.map((item) => (
          <div className="listing-item" key={item['@id']}>
            <div className="listing-body">
              {item.informationtype?.length > 0 && (
                <div className="spitzmarke">
                  {item.informationtype.map((el, index) => {
                    let tito = informationtypevocabulary
                      ? informationtypevocabulary[el] || el
                      : el;
                    return (
                      <ConditionalLink
                        key={tito}
                        to={
                          `/suche?q=&f=` +
                          'informationtype_agg.inner.informationtype_token' +
                          `%3A${el}&l=list&p=1`
                        }
                        condition={!isEditMode}
                      >
                        {tito}
                        {index < item.informationtype.length - 1 ? ', ' : null}
                      </ConditionalLink>
                    );
                  })}
                </div>
              )}
              <ConditionalLink
                to={flattenToAppURL(item['@id'])}
                condition={!isEditMode}
              >
                <div className="listing-body">
                  <h4 className={item.review_state}>{item.title}</h4>
                </div>
              </ConditionalLink>
              {item.kompasscomponent?.length > 0 && (
                <div className="metadata">
                  {item.kompasscomponent.map((el, index) => {
                    let tito = kompasscomponentvocabulary
                      ? kompasscomponentvocabulary[el] || el
                      : el;
                    return (
                      <ConditionalLink
                        key={tito}
                        to={
                          `/suche?q=&f=` +
                          'kompasscomponent_agg.inner.kompasscomponent_token' +
                          `%3A${el}&l=list&p=1`
                        }
              condition={!isEditMode}
                      >
                        {tito}
                        {index < item.kompasscomponent.length - 1 ? ', ' : null}
                      </ConditionalLink>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
ListingBlockTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default ListingBlockTemplate;
