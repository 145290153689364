/**
 * View for site admin to manage subscriptions
 */
import React, { useEffect } from 'react';
import { Portal } from 'react-portal';
import { useHistory } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Container, Segment, Tab } from 'semantic-ui-react';
import config from '@plone/volto/registry';
import { listActions } from '@plone/volto/actions';
import { Icon, Toolbar } from '@plone/volto/components';
import { getBaseUrl, getParentUrl } from '@plone/volto/helpers';
import SearchAndManageView from './SearchAndManageView';
import RightColumnFacets from '@plone/volto/components/manage/Blocks/Search/layout/RightColumnFacets';
import backSVG from '@plone/volto/icons/back.svg';

export const messages = defineMessages({
  success: {
    id: 'Success',
    defaultMessage: 'Success',
  },
  subscriptionsUpdated: {
    id: 'subscriptionsUpdated',
    defaultMessage: 'Subscriptions are updated.',
  },
  immediate: {
    id: 'immediate',
    defaultMessage: 'immediate',
  },
  digest: {
    id: 'digest',
    defaultMessage: 'digest',
  },
  distributionmodehelp: {
    id: 'distributionmodehelp',
    defaultMessage: 'immediate / digest',
  },
  messagechannels: {
    id: 'messagechannels',
    defaultMessage: 'Channels',
  },
  subscriptions: {
    id: 'subscriptions',
    defaultMessage: 'Subscriptions',
  },
  subscribers: {
    id: 'subscribers',
    defaultMessage: 'Subscribers',
  },
});

const ManageSubscriptions = () => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  /**
   * Subscribers
   */
  const subscribersblock_props = {
    variation: {
      id: 'facetsRightSide',
      title: 'Facets on right side',
      view: RightColumnFacets,
      isDefault: true,
    },
    id: 'breakingnews-subscribers',
    data: {
      '@type': 'search',
      listingBodyTemplate: 'subscriber',
      query: {
        b_size: '10',
        query: [
          {
            i: 'portal_type',
            o: 'plone.app.querystring.operation.selection.any',
            v: config.settings.breakingnews.portal_type_subscribers,
          },
        ],
        sort_on: 'sortable_title',
        sort_order: 'ascending',
      },
      showSearchInput: true,
      showSortOn: true,
      sortOnLabel: 'Sortieren nach',
      sortOnOptions: ['sortable_title'],
      variation: 'facetsRightSide',
      facets: [
        {
          '@id': 'facet1',
          field: {
            label: intl.formatMessage(messages.subscriptions),
            value: 'subscribedchannels',
          },
          label: intl.formatMessage(messages.subscriptions),
          value: 'subscribedchannels',
          multiple: true,
          title: intl.formatMessage(messages.messagechannels),
          type: 'checkboxFacet',
        },
      ],
    },
    searchData: {
      block: 'breakingnews-subscribers',
      b_size: '10',
      query: [
        {
          i: 'portal_type',
          o: 'plone.app.querystring.operation.selection.any',
          v: config.settings.breakingnews.portal_type_subscribers,
        },
      ],
      sort_on: 'sortable_title',
      sort_order: 'ascending',
    },
    path: '/',
    querystring: {},
    facets: {},
  };
  subscribersblock_props.extensions = {};

  /**
   * Subscriptions
   */
  const subscriptionsblock_props = {
    variation: {
      id: 'facetsRightSide',
      title: 'Facets on right side',
      view: RightColumnFacets,
      isDefault: true,
    },
    id: 'breakingnews-subscriptions',
    data: {
      '@type': 'search',
      listingBodyTemplate: 'subscriptions',
      query: {
        b_size: '10',
        query: [
          {
            i: 'portal_type',
            o: 'plone.app.querystring.operation.selection.any',
            v: config.settings.breakingnews.portal_type_content,
          },
        ],
        sort_on: 'sendDate',
        sort_order: 'descending',
        sort_order_boolean: true,
      },
      showSearchInput: true,
      showSortOn: true,
      sortOnLabel: 'Sortieren nach',
      sortOnOptions: ['sendDate'],
      variation: 'facetsRightSide',
      facets: [
        {
          '@id': 'facet1',
          field: {
            label: intl.formatMessage(messages.subscriptions),
            value: 'messagechannels',
          },
          label: intl.formatMessage(messages.subscriptions),
          value: 'messagechannels',
          multiple: true,
          title: intl.formatMessage(messages.messagechannels),
          type: 'checkboxFacet',
        },
      ],
    },
    // searchData: {
    //   block: 'breakingnews-subscriptions',
    //   b_size: '10',
    //   query: [
    //     {
    //       i: 'portal_type',
    //       o: 'plone.app.querystring.operation.selection.any',
    //       v: config.settings.breakingnews.portal_type_content,
    //     },
    //   ],
    //   sort_on: 'sendDate',
    //   sort_order: 'descending',
    // },
    path: '/',
    querystring: {},
    facets: {},
  };
  subscriptionsblock_props.extensions = {};

  useEffect(() => {
    dispatch(listActions('/'));
  }, [dispatch]);

  const panes = [
    {
      menuItem: intl.formatMessage(messages.subscriptions),
      pane: (
        <Tab.Pane attached={false}>
          <SearchAndManageView
            {...subscriptionsblock_props}
            path={getBaseUrl(subscriptionsblock_props.path)}
            mode="view"
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: intl.formatMessage(messages.subscribers),
      pane: (
        <Tab.Pane attached={false}>
          <SearchAndManageView
            {...subscribersblock_props}
            path={getBaseUrl(subscribersblock_props.path)}
            mode="view"
          />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <>
      <Container id="manage-subscriptions" className="view-wrapper manage-view">
        <h2>
          <FormattedMessage
            id="managesubscriptionheader"
            defaultMessage="Manage Subscriptions"
          />
        </h2>
        <p>
          <FormattedMessage
            id="managesubscriptionprolog"
            defaultMessage="Manage subscriptions of user and see an overview of pipelined and sent content."
          />
        </p>

        <Tab panes={panes} renderActiveOnly={false} />
      </Container>

      {__CLIENT__ && (
        <Portal node={document.getElementById('toolbar')}>
          <Toolbar
            pathname={location.pathname}
            hideDefaultViewButtons
            inner={
              <Link
                className="item"
                to="#"
                onClick={() => {
                  history.push(getParentUrl(location.pathname));
                }}
              >
                <Icon name={backSVG} className="contents circled" size="30px" />
              </Link>
            }
          />
        </Portal>
      )}
    </>
  );
};

export default ManageSubscriptions;
