import TableBlockView from '@plone/volto-slate/blocks/Table/TableBlockView';

const FooComponent = ({ className, data }) => {
  return (
    <div className={`stylewrapper ${className}`}>
      <TableBlockView data={data} />
    </div>
  );
};

export default FooComponent;
