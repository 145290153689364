/**
 * Slate text block
 * Changes
 * - take block label into concern
 * - take block style wrapper stuff into account
 *    https://6.docs.plone.org/volto/blocks/block-style-wrapper.html#the-styles-field
 * - serializeNodes from volto-slate-glossary to render terms with Tooltip
 */
// TODO default serializeNodes for cases where tooltips are not wanted. (zB props.properties['@type] for portal_type)

import React from 'react';
// import { matchPath } from 'react-router';
import { serializeNodes } from '@plone/volto-slate/editor/render';

import { Label, Segment } from 'semantic-ui-react';

import config from '@plone/volto/registry';

const CustomTextBlockView = (props) => {
  const { id, data, styling = {} } = props;
  const { value, override_toc, blocklabel } = data;
  const metadata = props.metadata || props.properties;

  // const { slateGlossary = { applyTo: [] } } = config.settings;
  // const toApply = slateGlossary.applyTo // is the serializeNodesCustom to be applied?
  //   .map((reg) => {
  //     const match = matchPath(props.path || '/', reg.match);
  //     return match ? { reg, match } : null;
  //   })
  //   .filter((reg) => reg);
  // TODO restrict tooltips to portal_type of settings.slateGlossary.applyTo

  let textblock = serializeNodes(
    value,
    (node, path) => {
      const res = { ...styling };
      if (node.type) {
        if (
          config.settings.slate.topLevelTargetElements.includes(node.type) ||
          override_toc
        ) {
          res.id = id;
        }
      }
      return res;
    },
    { metadata: metadata },
  );
  return blocklabel ? (
    <Segment padded className={`block labeledblock-wrapper ${props.className}`}>
      <Label attached="top left">{blocklabel}</Label>
      <div className={`labeledblock`}>{textblock}</div>
    </Segment>
  ) : (
    <div className={`stylewrapper ${props.className}`}>{textblock}</div>
  );
};

export default CustomTextBlockView;
