import React from 'react';

const PluginElement = (props) => {
  const { children, element } = props;
  const { data = {} } = element;

  const warning_type = data.warning_type || 'warning';

  return <span className={`inline-marker-${warning_type}`}>{children}</span>;
};

export default PluginElement;
