import React from 'react';

import { flattenToAppURL } from '@plone/volto/helpers';

const PDFManual2 = ({ file }) => {
  let filename_display = flattenToAppURL(file.download);
  filename_display = filename_display.replace('@@download', '@@display-file');

  return (
    <div className="pdf-wrapper">
      <iframe
        src={filename_display + '/manua.pdf'}
        id="manual_pdf"
        height="1100"
        width="100%"
        title="Anleitung als PDF"
        allowFullScreen="0"
      ></iframe>
    </div>
  );
};

export default PDFManual2;
