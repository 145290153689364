import React from 'react';
import PropTypes from 'prop-types';
// import { ConditionalLink } from '@plone/volto/components';
// import { flattenToAppURL } from '@plone/volto/helpers';

// import { isInternalURL } from '@plone/volto/helpers/Url/Url';

const DefaultTemplate = ({ items, linkMore, isEditMode }) => {
  return (
    <>
      <div className="items">
        {items.map((item) => (
          <div className="listing-item" key={item['@id']}>
            <div className="listing-body">
              <h4 className={item.review_state}>
                {item.first_name ? item.first_name + ' ' : ''}
                {item.last_name
                  ? item.last_name
                  : item.title
                  ? item.title
                  : item.id}
              </h4>
              {item.companyposition ? (
                <p className="companyposition">{item.companyposition}</p>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
DefaultTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default DefaultTemplate;
