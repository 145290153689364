import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

import { AllButTitleBlocksReadmore } from '~/components';

const DefaultTemplate = ({ items, linkMore, isEditMode }) => {
  const intl = useIntl();
  const location = useLocation();
  return (
    <div className="items listingexpandable">
      {items.map((item) => (
        <div className="listing-item" key={item['@id']}>
          <ConditionalLink
            to={flattenToAppURL(item['@id'])}
            condition={!isEditMode}
          >
            <div className="listing-body">
              <h4 className={item.review_state}>{item.title}</h4>
              {item.effective && !item.start && (
                <p className="listingdate discreet">
                  {moment(item.effective).format('DD.MM.YYYY')}
                </p>
              )}
              {item.start && (
                <p className="listingdate discreet">
                  {moment(item.start).format('DD.MM.YYYY')}
                </p>
              )}
            </div>
          </ConditionalLink>

          <AllButTitleBlocksReadmore
            content={item}
            intl={intl}
            location={location}
          />
        </div>
      ))}
    </div>
  );
};
DefaultTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default DefaultTemplate;
