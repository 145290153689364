/**
 * Search widget component.
 * @module components/theme/SearchWidget/SearchWidget
 * changes:
 * - remove glass icon
 * - add reset button
 * - removed change: onkeyup
 * - default search context: logged in: 'inside', anonymous: 'public'
 */

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Form, Input, Radio } from 'semantic-ui-react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import cx from 'classnames';

import { Icon } from '@plone/volto/components';

import resetWhiteboxSVG from '~/icons/reset_whitebox.svg';

import { trackSiteSearch } from '@eeacms/volto-matomo/utils';

const messages = defineMessages({
  search: {
    id: 'Search',
    defaultMessage: 'Search',
  },
  searchSite: {
    id: 'Search Site',
    defaultMessage: 'Search Site',
  },
});

/**
 * SearchWidget component class.
 * @class SearchWidget
 * @extends Component
 */
class SearchWidget extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    token: PropTypes.string,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    token: null,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.onChangeText = this.onChangeText.bind(this);
    this.handleResetSearch = this.handleResetSearch.bind(this);
    // this.onChangeSection = this.onChangeSection.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      text: '',
      searchcontext: props.token ? 'inside' : 'public',
      // section: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ visible: false });
    }
  }

  onFocus(event) {
    if (event.target.value.length > 2) {
      this.setState({ visible: true });
    }
  }

  /**
   * On change text
   * @method onChangeText
   * @param {object} event Event object.
   * @param {string} value Text value.
   * @returns {undefined}
   */
  onChangeText(event) {
    this.setState({ text: event.target.value });
    if (event.target.value && event.target.value.length > 2) {
      // const section = this.state.section ? `&path=${this.props.pathname}` : '';
      this.setState({ visible: true });
      let path =
        this.state.searchcontext !== 'public'
          ? `&path=${this.state.searchcontext}`
          : `&searchcontext=public`;
      this.props.history.push(
        `/search?SearchableText=${event.target.value}${path}`,
      );
      if (event.target.value && event.target.value.length > 4) {
        trackSiteSearch({
          keyword: event.target.value,
          category: 'Suche', // optional
          // count: 4, // optional
          documentTitle: 'Suche', // optional
          href: '/search', // optional
        });
      }
    }
  }

  /**
   * on change search context handler
   * @method onChangeSearchcontext
   * @param {event} event Event object.
   * @param {value} value String.
   * @returns {undefinded}
   */
  onChangeSearchcontext(event, searchcontext) {
    let path =
      searchcontext !== 'public'
        ? `&path=${searchcontext}`
        : `&searchcontext=public`;
    this.setState({
      searchcontext: searchcontext,
    });
    if (searchcontext === 'dokumentation') {
      this.props.history.push(
        `/suche?q=${this.state.text}&l=list&order=asc&p=1&s=10&sort=bestmatch`,
      );
    } else {
      this.props.history.push(
        `/search?SearchableText=${this.state.text}${path}`,
      );
    }
    event.preventDefault();
  }

  handleResetSearch(event) {
    // console.log('handleResetSearch', event.key);
    this.setState({
      text: '',
    });
    this.props.history.push(`/search`);
    document.getElementsByName('SearchableText')[0].focus();
    event.preventDefault();
  }

  // /**
  //  * On change section
  //  * @method onChangeSection
  //  * @param {object} event Event object.
  //  * @param {bool} checked Section checked.
  //  * @returns {undefined}
  //  */
  // onChangeSection(event, { checked }) {
  //   this.setState({
  //     section: checked,
  //   });
  // }

  /**
   * Submit handler
   * @method onSubmit
   * @param {event} event Event object.
   * @returns {undefined}
   */
  onSubmit(event) {
    // console.log('onSubmit');
    event.preventDefault();
    this.setState({ visible: true });
    let path =
      this.state.searchcontext !== 'public'
        ? `&path=${this.state.searchcontext}`
        : `&searchcontext=public`;
    this.props.history.push(`/search?SearchableText=${this.state.text}${path}`);
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <>
        <Form action="/search" onSubmit={this.onSubmit}>
          <Form.Field className="searchbox">
            <Input
              aria-label={this.props.intl.formatMessage(messages.search)}
              onChange={this.onChangeText}
              onFocus={this.onFocus.bind(this)}
              name="SearchableText"
              value={this.state.text}
              transparent
              autoComplete="off"
              placeholder={this.props.intl.formatMessage(messages.searchSite)}
            />
          </Form.Field>
          {this.props.token && (
            <Form.Group
              className={cx('searchcontext', {
                visible: this.state.visible,
              })}
            >
              <Form.Field>
                <Radio
                  label="Website"
                  name="searchcontext"
                  id="searchcontext-public"
                  value="public"
                  checked={this.state.searchcontext === 'public'}
                  onChange={(e, { value }) =>
                    this.onChangeSearchcontext(e, value)
                  }
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="IGIB-Inside"
                  name="searchcontext"
                  id="searchcontext-inside"
                  value="inside"
                  checked={this.state.searchcontext === 'inside'}
                  onChange={(e, { value }) =>
                    this.onChangeSearchcontext(e, value)
                  }
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="Dokumentation"
                  name="searchcontext"
                  id="searchcontext-dokumentation"
                  value="dokumentation"
                  checked={this.state.searchcontext === 'dokumentation'}
                  onChange={(e, { value }) =>
                    this.onChangeSearchcontext(e, value)
                  }
                />
              </Form.Field>
            </Form.Group>
          )}
        </Form>

        <Button
          icon
          basic
          onClick={this.handleResetSearch}
          className={
            this.state.text?.length
              ? 'deleteFilter selected'
              : 'deleteFilter unselected'
          }
          title="Suchbegriff löschen"
        >
          <Icon name={resetWhiteboxSVG} size="30px" />
        </Button>
        {/* <IconSemantic
          className={
            this.state.text?.length
              ? 'deleteFilter selected'
              : 'deleteFilter unselected'
          }
          name="delete"
          onClick={this.handleResetSearch}
        /> */}
      </>
    );
  }
}

export default compose(
  withRouter,
  injectIntl,
  connect(
    (state, props) => ({
      token: state.userSession.token,
    }),
    {},
  ),
)(SearchWidget);
