import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  // addBlock: {
  //   id: 'mylabel',
  //   defaultMessage: 'mylabel',
  // },
  edit: {
    id: 'Edit link',
    defaultMessage: 'Edit link',
  },
  delete: {
    id: 'Remove link',
    defaultMessage: 'Remove link',
  },
  emailaddress: {
    id: 'Email address',
    defaultMessage: 'Email address',
  },
  internallink: {
    id: 'Internal Link',
    defaultMessage: 'Internal link',
  },
});
