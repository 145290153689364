import React from 'react';
import { concat } from 'lodash';
import { useLocation } from 'react-router-dom';
import { Image } from 'semantic-ui-react';

function importAll(r) {
  return r.keys().map(r);
}

const dayOfYear = (date) =>
  Math.floor((date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24);

// Array of filenames
const heroImagesFinalSelection = concat(
  importAll(require.context('./Auswahl', false, /\.(jp2|png|jpe?g|svg)$/)),
);
const heroImagesFinalSelectionNumber = heroImagesFinalSelection.length;
// console.log(
//   'heroImagesFinalSelection array of filenames',
//   heroImagesFinalSelection,
//   heroImagesFinalSelectionNumber,
// );

const HeroImage = () => {
  const count = dayOfYear(new Date()) % heroImagesFinalSelectionNumber;

  const search = useLocation().search;
  const mycount = new URLSearchParams(search).get('heroimage');

  const heroimagefilename = heroImagesFinalSelection[mycount ?? count];

  return <Image src={heroimagefilename} alt="hero image" />;
};

export default HeroImage;
