import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { map, isEqual, last, reduce, without } from 'lodash';
import moment from 'moment';
import { Portal } from 'react-portal';
import { Button, Container, List, Table } from 'semantic-ui-react';
// import OutsideClickHandler from 'react-outside-click-handler';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import { Icon } from '@plone/volto/components';
import { ToggleBookmarkButton } from '@plone-collective/volto-bookmarks/components';

import upSVG from '~/icons/up.svg';
import historySVG from '~/icons/history.svg';
import infoSVG from '~/icons/info.svg';
// import upActiveSVG from '~/icons/up_active.svg';
import historyActiveSVG from '~/icons/history_active.svg';
import infoActiveSVG from '~/icons/info_active.svg';
// import downloadSVG from '~/icons/download.svg';
// import viewSVG from '@plone/volto/icons/showcase.svg';
// import printerSVG from '@plone/volto/icons/printer.svg';
import viewSVG from '~/icons/showcase.svg';
import printerSVG from '~/icons/printer.svg';
import voteSVG from '~/icons/vote-up.svg';
import voteActiveSVG from '~/icons/vote-up-active.svg';
import { flattenToAppURL } from '@plone/volto/helpers';
import CustomerSatisfaction from '~/components/CustomerSatisfaction';

const MyHistory = ({ history }) => {
  return (
    __CLIENT__ && (
      <Table celled className="manualhistory_list">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Geändert am</Table.HeaderCell>
            <Table.HeaderCell>Was</Table.HeaderCell>
            <Table.HeaderCell>Version</Table.HeaderCell>
            <Table.HeaderCell>Wer</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {history.items.map((item) => (
            <Table.Row key={item.historydate}>
              <Table.Cell>
                {item.historydate && moment(item.historydate).format('L')}
              </Table.Cell>
              <Table.Cell>{item.historytopic}</Table.Cell>
              <Table.Cell>{item.historyversion}</Table.Cell>
              <Table.Cell className="historyauthor">
                {item.historyauthor}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    )
  );
};

function getSimplifiedHeadingBlocksList(properties) {
  let foo = map(properties.blocks_layout?.items || [], (block) => {
    let bar = properties['blocks'][block];
    if (bar) {
      bar.id = block;
      return bar;
    }
    return null;
  });
  let blocks = foo.filter(
    (block) =>
      block &&
      block['@type'] === 'slate' &&
      block.value &&
      ['h2', 'h3', 'h4'].indexOf(block.value[0].type) > -1,
  );
  return map(blocks, (block) => ({
    type: block.value[0].type,
    text: block.value[0].children[0].text,
    id: block.id,
    children: [],
    pageurl: properties['@id'],
  }));
}

export const MyTOC = ({ properties }) => {
  // here code for Volto
  let topH = 'h2';
  let mediumH = 'h3';
  let lowH = 'h4';
  let llst = getSimplifiedHeadingBlocksList(properties);
  let initialresult = { previous: null, resultlist: [] };
  let dummyMediumH = {
    type: mediumH,
    text: '',
    id: '',
    children: [],
  };
  let vlst = llst.length
    ? reduce(
        llst,
        function (result, value, index) {
          // skip to first topH
          // noch nix gepushed und heading ist nicht voll fett
          // nothing pushed and heading is not top heading topH
          if (isEqual(result, initialresult) && value.type !== topH) {
            return result;
          }
          // from here on there is already a top heading topH pushed
          if (value.type === topH) {
            result.resultlist.push(value);
          } else if (value.type === mediumH) {
            // result.resultlist.push(value);
            last(result.resultlist).children.push(value);
          } else if (value.type === lowH) {
            let lastTopH = last(result.resultlist);
            // handle case: no mediumH
            if (lastTopH.children.length === 0) {
              lastTopH.children.push(dummyMediumH);
            }
            last(lastTopH.children).children.push(value);
          }
          return result;
        },
        { ...{ previous: null, resultlist: [] } }, // ! do not use a variable, it's manipulated.
      )
    : [];
  return (
    __CLIENT__ &&
    properties && (
      <List ordered className="toclist">
        {map(vlst.resultlist, (el, elidx) => (
          <List.Item key={elidx} className={`tocitem-${el.type}`}>
            <AnchorLink href={`#${el.id}`}>{el.text}</AnchorLink>
            {el.children.length > 0 && (
              <List>
                {map(el.children, (chld, chldidx) => (
                  <List.Item key={chldidx} className={`tocitem-${chld.type}`}>
                    {true && (
                      <AnchorLink
                        href={
                          // chld.text.length > 0
                          `#${chld.id}`
                        }
                      >
                        {chld.text || '_'}
                      </AnchorLink>
                    )}
                    {chld.children.length > 0 && (
                      <List>
                        {map(chld.children, (grandchild, grandchildidx) => (
                          <List.Item
                            key={grandchildidx}
                            className={`tocitem-${grandchild.type}`}
                          >
                            <AnchorLink href={`#${grandchild.id}`}>
                              {grandchild.text}
                            </AnchorLink>
                          </List.Item>
                        ))}
                      </List>
                    )}
                  </List.Item>
                ))}
              </List>
            )}
          </List.Item>
        ))}
      </List>
    )
  );
};

const MetaInfoToolbar = ({ content, location, token }) => {
  const intl = useIntl();
  moment.locale(intl.locale);
  let portaltype = content['@type'];

  const [metainfoaction, setMetainfoaction] = useState([]);

  useEffect(() => {
    setMetainfoaction([]); // reset on change of path
  }, [location.pathname]);

  function infoHandler() {
    if (metainfoaction.includes('info')) {
      setMetainfoaction(without(metainfoaction, 'info'));
    } else {
      setMetainfoaction([
        ...without(metainfoaction, 'history', 'vote'),
        'info',
      ]);
      document.getElementById('metainfocontent').scrollIntoView({
        behavior: 'smooth',
      });
    }
  }
  function historyHandler() {
    if (metainfoaction.includes('history')) {
      setMetainfoaction(without(metainfoaction, 'history'));
    } else {
      setMetainfoaction([
        ...without(metainfoaction, 'info', 'vote'),
        'history',
      ]);
      document.getElementById('metainfocontent').scrollIntoView({
        behavior: 'smooth',
      });
    }
  }
  function voteHandler() {
    if (metainfoaction.includes('vote')) {
      setMetainfoaction(without(metainfoaction, 'vote'));
    } else {
      setMetainfoaction([
        ...without(metainfoaction, 'history', 'info'),
        'vote',
      ]);
      document.getElementById('metainfocontent').scrollIntoView({
        behavior: 'smooth',
      });
    }
  }
  function upHandler() {
    document.querySelector('.navigation-dropdownmenu').scrollIntoView({
      behavior: 'smooth',
    });
  }
  function printHandler(event) {
    event.preventDefault();
    let printMetainfo = [];
    if (getSimplifiedHeadingBlocksList(content).length > 0) {
      printMetainfo.push('info');
    }
    if (content.manualhistory?.items?.length > 0) {
      printMetainfo.push('history');
    }
    printMetainfo.length > 0 && setMetainfoaction(printMetainfo);

    const doPrint = () => window.print();
    setTimeout(doPrint, 10);
  }

  function presentationHandler(event) {
    event.preventDefault();
    let href = flattenToAppURL(content.manualfile.download).replace(
      '@@download',
      '@@display-file',
    );
    window.open(href, '_blank');
  }

  let condition_bookmark =
    location.pathname.startsWith('/dokumentation') ||
    location.pathname.startsWith('/inside') ||
    location.pathname.startsWith('/suche');

  return condition_bookmark || portaltype === 'Manual' ? (
    <Container id="metainfotoolbar-wrapper">
      <div id="metainfotoolbar">
        {condition_bookmark ? <ToggleBookmarkButton /> : null}

        {portaltype === 'Manual' &&
          getSimplifiedHeadingBlocksList(content).length > 0 && (
            <Button
              icon
              basic
              onClick={infoHandler}
              className="metainfotoolbar-button info-button"
              key="info-button"
              title="Inhaltsverzeichnis anzeigen"
            >
              {metainfoaction.includes('info') ? (
                <Icon name={infoActiveSVG} size="30px" />
              ) : (
                <Icon name={infoSVG} size="30px" />
              )}
            </Button>
          )}
        {portaltype === 'Manual' && content.manualhistory?.items?.length > 0 && (
          <Button
            icon
            basic
            onClick={historyHandler}
            className="metainfotoolbar-button history-button"
            key="history-button"
            title="History anzeigen"
          >
            {metainfoaction.includes('history') ? (
              <Icon name={historyActiveSVG} size="30px" />
            ) : (
              <Icon name={historySVG} size="30px" />
            )}
          </Button>
        )}
        {portaltype === 'Manual' && (
          <Button
            icon
            basic
            onClick={voteHandler}
            className="metainfotoolbar-button vote-button"
            key="vote-button"
            title="Rückmeldung abgeben"
          >
            {metainfoaction.includes('vote') ? (
              <Icon name={voteActiveSVG} size="30px" />
            ) : (
              <Icon name={voteSVG} size="30px" />
            )}
          </Button>
        )}

        {/* Manual with blocks text */}
        {portaltype === 'Manual' && !content.manualfile?.download && (
          <Button
            icon
            basic
            onClick={printHandler}
            title="Seite drucken"
            alt="Seite drucken"
            className="metainfotoolbar-button print-button text-pdf"
          >
            <Icon name={printerSVG} size="30px" />
          </Button>
        )}
        {/* Manual with PDF attached */}
        {portaltype === 'Manual' && content.manualfile?.download && (
          <Button
            icon
            basic
            onClick={presentationHandler}
            title="Vollbildmodus"
            alt="Vollbildmodus"
            className="metainfotoolbar-button showcase-button pdf-pdf"
          >
            <Icon name={viewSVG} size="30px" />
          </Button>
        )}
        {portaltype === 'Manual' && (
          <Button
            icon
            basic
            onClick={upHandler}
            className="metainfotoolbar-button up-button"
            key="up-button"
            title="zum Seitenanfang"
          >
            <Icon name={upSVG} size="30px" />
          </Button>
        )}

        {/* End buttons group */}

        {metainfoaction.includes('info') ? (
          <Portal
            node={__CLIENT__ && document.getElementById('metainfocontent')}
          >
            <div className="metainfomodal toc">
              <div className="metainfomodalinner">
                <h2>Inhaltsverzeichnis</h2>
                <MyTOC properties={content} />
              </div>
            </div>
          </Portal>
        ) : null}
        {metainfoaction.includes('history') ? (
          <Portal
            node={__CLIENT__ && document.getElementById('metainfocontent')}
          >
            <div className="metainfomodal history">
              <div className="metainfomodalinner">
                <h2>History</h2>
                <MyHistory history={content.manualhistory} />
              </div>
            </div>
          </Portal>
        ) : null}
        {metainfoaction.includes('vote') ? (
          <Portal
            node={__CLIENT__ && document.getElementById('metainfocontent')}
          >
            <div className="metainfomodal vote">
              <div className="metainfomodalinner">
                <CustomerSatisfaction />
              </div>
            </div>
          </Portal>
        ) : null}
      </div>
    </Container>
  ) : (
    <></>
  );
};

export default MetaInfoToolbar;
