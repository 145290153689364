/**
 * OBSOLETE since all-in-one search for documentation, inside, website
 * Search component.
 * @module components/theme/Search/Search
 * changes
 * - dispatch action searchContent with allowedPortalTypes
 *       portal_type: ['Document', 'Event', 'News Item', 'OverviewPage']
 *  ignore site settings, as we want for example search for Persons in content folder view,
 *  but do not want to expose Person to public global search
 * - search result items total: plural, singular
 * - no alphabetic sort (sortable_title)
 * - label for sort by effective
 * - results: no 'read more' (tileFooter)
 * - filter search results if no querystring
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { asyncConnect } from '@plone/volto/helpers';
import { FormattedMessage } from 'react-intl';
import { Portal } from 'react-portal';
import { Container, Pagination, Button, Header } from 'semantic-ui-react';
import qs from 'query-string';
import classNames from 'classnames';

import config from '@plone/volto/registry';
import { Helmet } from '@plone/volto/helpers';
import { searchContent } from '@plone/volto/actions';
import { SearchTags, Toolbar, Icon } from '@plone/volto/components';

import paginationLeftSVG from '@plone/volto/icons/left-key.svg';
import paginationRightSVG from '@plone/volto/icons/right-key.svg';

const allowedPortalTypes = [
  // 'OverviewPage',
  'Document',
  'Event',
  'News Item',
  'File',
];

/**
 * Search class.
 * @class SearchComponent
 * @extends Component
 */
class Search extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    searchContent: PropTypes.func.isRequired,
    searchableText: PropTypes.string,
    subject: PropTypes.string,
    path: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string,
        '@type': PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
      }),
    ),
    pathname: PropTypes.string.isRequired,
    token: PropTypes.string,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    items: [],
    searchableText: null,
    subject: null,
    path: null,
    token: null,
  };

  constructor(props) {
    super(props);
    this.state = { currentPage: 1, isClient: false, active: 'relevance' };
  }

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.doSearch();
    this.setState({ isClient: true });
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (this.props.location.search !== nextProps.location.search) {
      this.doSearch();
    }
  };

  /**
   * Search based on the given searchableText, subject and path.
   * @method doSearch
   * @param {string} searchableText The searchable text string
   * @param {string} subject The subject (tag)
   * @param {string} path The path to restrict the search to
   * @returns {undefined}
   */

  doSearch = () => {
    const options = qs.parse(this.props.history.location.search);
    this.setState({ currentPage: 1 });
    this.props.searchContent('', {
      ...options,
      portal_type: allowedPortalTypes,
    });
  };

  handleQueryPaginationChange = (e, { activePage }) => {
    const { settings } = config;
    window.scrollTo(0, 0);
    let options = qs.parse(this.props.history.location.search);
    this.setState({ currentPage: activePage }, () => {
      this.props.searchContent('', {
        ...options,
        portal_type: allowedPortalTypes,
        b_start: (this.state.currentPage - 1) * settings.defaultPageSize,
      });
    });
  };

  onSortChange = (event, sort_order) => {
    let options = qs.parse(this.props.history.location.search);
    options.sort_on = event.target.name;
    options.sort_order = sort_order || 'ascending';
    if (event.target.name === 'relevance') {
      delete options.sort_on;
      delete options.sort_order;
    }
    let searchParams = qs.stringify(options);
    this.setState({ currentPage: 1, active: event.target.name }, () => {
      // eslint-disable-next-line no-restricted-globals
      this.props.history.replace({
        search: searchParams,
      });
    });
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { settings } = config;
    let searchresults = this.props.items;
    if (!this.props.searchableText) {
      searchresults = searchresults.filter((el) =>
        allowedPortalTypes.includes(el['@type']),
      );
    }
    const searchoptions = qs.parse(this.props.history.location.search);
    // ? searchoptions.path.charAt(0).toUpperCase() + searchoptions.path.slice(1)
    const searchpathoption = searchoptions.path ? 'IGIB-Inside' : '';
    const searchcontextoption = searchoptions.searchcontext ? 'Website' : '';
    const searchcontext = searchpathoption || searchcontextoption;
    return (
      <Container id="page-search">
        <Helmet title="Suche" />
        <div className="container">
          <article id="content">
            <header>
              <h1 className="documentFirstHeading">
                {this.props.searchableText ? (
                  <FormattedMessage
                    id="Search results for {term} in {searchcontext}"
                    defaultMessage="Search results for {term} in {searchcontext}"
                    values={{
                      term: <q>{this.props.searchableText}</q>,
                      searchcontext: <>{searchcontext}</>,
                    }}
                  />
                ) : (
                  <FormattedMessage
                    id="Search results"
                    defaultMessage="Search results"
                  />
                )}
              </h1>

              {/* <SearchTags /> */}

              {this.props.search?.items_total > 0 ? (
                <div className="items_total">
                  {this.props.search.items_total}{' '}
                  {this.props.search?.items_total === 1 ? (
                    <FormattedMessage
                      id="result found"
                      defaultMessage="result"
                    />
                  ) : (
                    <FormattedMessage
                      id="results found"
                      defaultMessage="results"
                    />
                  )}
                  <Header>
                    <Header.Content className="header-content">
                      <div className="sort-by">
                        <FormattedMessage
                          id="Sort By:"
                          defaultMessage="Sort by:"
                        />
                      </div>
                      <Button
                        onClick={(event) => {
                          this.onSortChange(event);
                        }}
                        name="relevance"
                        size="tiny"
                        className={classNames('button-sort', {
                          'button-active': this.state.active === 'relevance',
                        })}
                      >
                        <FormattedMessage
                          id="Relevance"
                          defaultMessage="Relevance"
                        />
                      </Button>
                      <Button
                        onClick={(event) => {
                          this.onSortChange(event, 'reverse');
                        }}
                        name="effective"
                        size="tiny"
                        className={classNames('button-sort', {
                          'button-active': this.state.active === 'effective',
                        })}
                      >
                        Datum
                      </Button>
                    </Header.Content>
                  </Header>
                </div>
              ) : (
                <div>
                  <FormattedMessage
                    id="No results found"
                    defaultMessage="No results found"
                  />
                </div>
              )}
            </header>
            <section id="content-core">
              {searchresults.map((item) => (
                <article className="tileItem" key={item['@id']}>
                  <Link
                    to={item['@id']}
                    className="summary url"
                    title={item['@type']}
                  >
                    <h2 className={'tileHeadline ' + item.review_state}>
                      {item.title}
                    </h2>
                  </Link>
                  {item.description && (
                    <div className="tileBody">
                      <span className="description">{item.description}</span>
                    </div>
                  )}
                  <div className="visualClear" />
                </article>
              ))}

              {this.props.search?.batching && (
                <div className="search-footer">
                  <Pagination
                    activePage={this.state.currentPage}
                    totalPages={Math.ceil(
                      this.props.search.items_total / settings.defaultPageSize,
                    )}
                    onPageChange={this.handleQueryPaginationChange}
                    firstItem={null}
                    lastItem={null}
                    prevItem={{
                      content: <Icon name={paginationLeftSVG} size="18px" />,
                      icon: true,
                      'aria-disabled': !this.props.search.batching.prev,
                      className: !this.props.search.batching.prev
                        ? 'disabled'
                        : null,
                    }}
                    nextItem={{
                      content: <Icon name={paginationRightSVG} size="18px" />,
                      icon: true,
                      'aria-disabled': !this.props.search.batching.next,
                      className: !this.props.search.batching.next
                        ? 'disabled'
                        : null,
                    }}
                  />
                </div>
              )}
            </section>
          </article>
        </div>
        {this.state.isClient && (
          <Portal node={document.getElementById('toolbar')}>
            <Toolbar
              pathname={this.props.pathname}
              hideDefaultViewButtons
              inner={<span />}
            />
          </Portal>
        )}
      </Container>
    );
  }
}

export const __test__ = connect(
  (state, props) => ({
    items: state.search.items,
    searchableText: qs.parse(props.history.location.search).SearchableText,
    pathname: props.history.location.pathname,
  }),
  { searchContent },
)(Search);

export default compose(
  connect(
    (state, props) => ({
      items: state.search.items,
      searchableText: qs.parse(props.history.location.search).SearchableText,
      pathname: props.location.pathname,
      token: state.userSession.token,
    }),
    { searchContent },
  ),
  asyncConnect([
    {
      key: 'search',
      promise: ({ location, store: { dispatch } }) =>
        dispatch(
          searchContent('', {
            ...qs.parse(location.search),
            // use_site_search_settings: 1,
            portal_type: allowedPortalTypes,
          }),
        ),
    },
  ]),
)(Search);
