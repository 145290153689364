import { defineMessages } from 'react-intl';
import { composeSchema } from '@plone/volto/helpers';
import { withRestriction } from './RestrictedBlock';

const messages = defineMessages({
  restrictorGroups: {
    id: 'Restrictor groups',
    defaultMessage: 'Restrictor groups',
  },
  restrictorGroupsDescription: {
    id: 'Restrictor groups description',
    defaultMessage: 'Groups to be restricted to',
  },
});

export const enableBlockRestriction = (config) => {
  const blocksConfig = config.blocks.blocksConfig;

  Object.keys(blocksConfig).forEach((block_id) => {
    if (!['title'].includes(block_id)) {
      // Add field restrictorGroups (token of vocabulary item)
      const addRestrictorGroup = ({ schema, formData, intl }) => {
        schema.properties.restrictorGroups = {
          title: intl.formatMessage(messages.restrictorGroups),
          description: intl.formatMessage(messages.restrictorGroupsDescription),
          vocabulary: { '@id': 'plone.app.vocabularies.Groups' },
          widget: 'array',
        };
        schema.fieldsets[0].fields.push('restrictorGroups');
        return schema;
      };
      blocksConfig[block_id].schemaEnhancer = composeSchema(
        blocksConfig[block_id].schemaEnhancer,
        addRestrictorGroup,
      );

      // wrap block view with restriction
      blocksConfig[block_id].view = withRestriction(
        blocksConfig[block_id].view,
      );
      blocksConfig[block_id].edit = withRestriction(
        blocksConfig[block_id].edit,
      );
    }
  });
};

export { withRestriction };
