import { composeSchema } from '@plone/volto/helpers';
import { addStyling } from '@plone/volto/helpers/Extensions/withBlockSchemaEnhancer';
// import { SIMPLELINK, LINK } from '@plone/volto-slate/constants';
// import { LinkElement } from './editor/plugins/CustomLinkPlugin/render';
// import { LinkElement as LinkElementFormerEEALink } from './editor/plugins/FormerEEALinkPlugin/render';
import { listUsersPlusReducer } from './reducers';
import { groupsharingReducer, groupsharingcontentsReducer } from './reducers';
import installTooltipPlugin from './editor/plugins/TooltipPlugin';
import installWarningPlugin from './editor/plugins/WarningPlugin';
// import installAdvancedLinkPlugin from './editor/plugins/AdvancedLinkPlugin';
import { TOOLTIP } from './editor/plugins/TooltipPlugin/constants';
// import { WARNING } from './editor/plugins/WarningPlugin/constants';
import TableBlockView from './components/Table/TableBlockView';

import './volto-tweaks-rohberg.less';

const applyConfig = (config) => {
  // Enable option for restriction of block to groups by editor IN YOUR PROJECT
  // Do it in your project to apply the change not only to default blocks but also blocks of add-ons
  // enableBlockRestriction(config);

  // Slate ------------------------------------
  // Download: for a direct download: add /@@download/file
  const { slate } = config.settings;
  // Link -------------
  // New @plone/volto-slate link:
  // slate.elements[SIMPLELINK] = LinkElement;
  // Traditional @eea/volto-slate link (https://github.com/eea/volto-slate/tree/0cc30e7b7aadfd43356a8e4bf986a21c818ad3c0):
  // Revive old LINK in project, not here in volto-tweaks-rohberg
  // Don't just render: // slate.elements[LINK] = LinkElementFormerEEALink;
  // see below installAdvancedLinkPlugin
  // config = installAdvancedLinkPlugin(config);

  slate.toolbarButtons = [...(slate.toolbarButtons || [])];
  // New Slate link or old type
  const link_button_id = slate.toolbarButtons.includes('link') ? 'link' : 'a';
  // Plugins
  slate.toolbarButtons.splice(
    slate.toolbarButtons.indexOf(link_button_id),
    0,
    TOOLTIP,
  );
  slate.expandedToolbarButtons = [
    ...(slate.expandedToolbarButtons || []),
    TOOLTIP,
  ];
  config = installTooltipPlugin(config);
  config = installWarningPlugin(config);

  // Ensure blockquote and callout
  if (!config.settings.slate.toolbarButtons.includes('blockquote')) {
    config.settings.slate.toolbarButtons.push('blockquote');
  }
  if (!config.settings.slate.toolbarButtons.includes('callout')) {
    config.settings.slate.toolbarButtons.push('callout');
  }
  config.settings.slate.toolbarButtons = config.settings.slate.toolbarButtons.filter(
    (el) => !['table', 'sub', 'sup'].includes(el),
  );

  // Reducer
  config.addonReducers = {
    ...config.addonReducers,
    listUsersPlus: listUsersPlusReducer,
    groupsharing: groupsharingReducer,
    groupsharingcontents: groupsharingcontentsReducer,
  };

  // Vocabulary
  config.settings.rohbergVoltoTweaksRohberg = {
    personfieldsVocabulary: 'foo.personfields',
  };

  // Table block: Additional option for font-size
  const addCustomTableOptions = ({ schema, intl }) => {
    addStyling({ schema, intl });

    schema.properties.styles.schema.properties.FontSize = {
      title: 'Schriftgrösse',
      default: 'normal',
      isMulti: false,
      choices: [
        ['normal', 'normal'],
        ['small', 'klein'],
      ],
    };
    schema.properties.styles.schema.fieldsets[0].fields.push('FontSize');
    return schema;
  };

  config.blocks.blocksConfig.slateTable.schemaEnhancer = composeSchema(
    config.blocks.blocksConfig.slateTable.schemaEnhancer,
    addCustomTableOptions,
  );
  // Wrap with style wrapper
  config.blocks.blocksConfig.slateTable.view = TableBlockView;

  return config;
};

export default applyConfig;
