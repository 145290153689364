/**
 * Bookmark reducers
 */

import { GET_BOOKMARKS_ADMIN } from '../constants/ActionTypes';

const initialState = {
  error: null,
  items: [],
  bookmark: null,
  loaded: false,
  loading: false,
  delete: 'loaded', // needed to trigger fetching bookmarks,
  // as delete does not get updated bookmarks from backend.
};

export default function myreducer(state = initialState, action = {}) {
  switch (action.type) {
    // list of bookmarks
    case `${GET_BOOKMARKS_ADMIN}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_BOOKMARKS_ADMIN}_SUCCESS`:
      return {
        ...state,
        error: null,
        bookmarks: action.result,
        loaded: true,
        loading: false,
      };
    case `${GET_BOOKMARKS_ADMIN}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };

    default:
      return state;
  }
}
