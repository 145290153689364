import React from 'react';
import { UniversalLink } from '@plone/volto/components';
import './styles.less';

export const LinkElement = ({ attributes, children, element, mode }) => {
  let url = element.url;
  const { link } = element.data || {};

  const internal_link = link?.internal?.internal_link?.[0]?.['@id'];
  const external_link = link?.external?.external_link;
  const email = link?.email;
  const internal_hash = link?.hash?.internal_hash.id;

  let href = email
    ? `mailto:${email.email_address}${
        email.email_subject ? `?subject=${email.email_subject}` : ''
      }`
    : external_link || internal_link || url || '#';

  const { title } = element?.data || {};

  // Download PDF, etc,
  let download = false;
  if (
    !external_link &&
    internal_link &&
    ['.pdf', '.xls', '.xlsx', '.docx', 'pptx'].some((fileextension) =>
      href.endsWith(fileextension),
    )
  ) {
    download = true;
    href = `${href}/@@download/file`;
  }

  return mode === 'view' ? (
    <>
      {internal_hash ? (
        <a href={`#${internal_hash}`} title={title}>
          {children}
        </a>
      ) : (
        <UniversalLink
          href={href || '#'}
          openLinkInNewTab={link?.external?.target === '_blank'}
          title={title}
          download={download}
        >
          {children}
        </UniversalLink>
      )}
    </>
  ) : (
    <span {...attributes} className="slate-editor-link">
      {children}
    </span>
  );
};
