import {
  GET_GROUPUSERS,
  GET_SHAREDCONTENT,
  GET_SHARINGPLUS,
} from '../constants/ActionTypes';

export function getGroupUsers(groupid) {
  return {
    type: GET_GROUPUSERS,
    request: {
      op: 'get',
      path: `/@groupusers?groupid=${groupid}`,
    },
  };
}

export function getSharedContent(groupid) {
  return {
    type: GET_SHAREDCONTENT,
    request: {
      op: 'get',
      path: `/@sharedcontent?groupid=${groupid}`,
    },
  };
}

/**
 * Get sharing function.
 * Change: Additional subrequest for multiple requests
 * @function getSharingPlus
 * @param {string} url Content url.
 * @param {string} search Search value.
 * @param {string} subrequest Key for multiple requests.
 * @returns {Object} Get sharing action.
 */
export function getSharingPlus(url, subrequest, search = '') {
  return {
    type: GET_SHARINGPLUS,
    subrequest,
    request: {
      op: 'get',
      path: `${url}/@sharing${search !== '' ? `?search=${search}` : ''}`,
    },
  };
}
