export const WarningEditorSchema = {
  title: 'Marker',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['warning_type'],
    },
  ],
  properties: {
    warning_type: {
      title: 'Text',
      type: 'string',
      factory: 'Choice',
      choices: [
        ['warning', 'Warning'],
        ['error', 'Error'],
      ],
      default: 'warning',
    },
  },
  required: [],
};
