import React from 'react';

import ListingTOCItem from './ListingTOCItem';

const DefaultTemplate = ({
  items,
  linkMore,
  isEditMode,
  showEffective,
  showDescription,
}) => {
  return (
    <div className="items listingtoc">
      {items.map((item) => (
        <div className="listing-item" key={item['@id']}>
          <ListingTOCItem
            content={item}
            isEditMode={isEditMode}
            showEffective={showEffective}
            showDescription={showDescription}
          />
        </div>
      ))}
    </div>
  );
};
export default DefaultTemplate;
