/**
 * View that lists groups and users.
 * Provides a download action in toolbar.
 */

import React from 'react';
import { map } from 'lodash';
import { Link } from 'react-router-dom';
import { Accordion, Container, Divider } from 'semantic-ui-react';
import AnimateHeight from 'react-animate-height';
import { useSelector, useDispatch } from 'react-redux';

import cx from 'classnames';
import { Icon } from '@plone/volto/components';
import rightSVG from '@plone/volto/icons/right-key.svg';
import downSVG from '@plone/volto/icons/down-key.svg';
// import AnimateHeight from 'react-animate-height';

import { flattenToAppURL } from '@plone/volto/helpers';
import { listGroups, searchContent } from '@plone/volto/actions';
import { Plug } from '@plone/volto/components/manage/Pluggable';

import {
  getGroupUsers,
  getSharedContent,
} from '@rohberg/volto-tweaks-rohberg/actions';
import { ExportUsers } from '@rohberg/volto-tweaks-rohberg/components';

function fullnameSorter(a, b) {
  const titleA = a.last_name?.toUpperCase();
  const titleB = b.last_name?.toUpperCase();
  if (titleA < titleB) {
    return -1;
  } else if (titleA > titleB) {
    return 1;
  }
  return 0;
}

const SharingView = ({ content }) => {
  const dispatch = useDispatch();

  const groups = useSelector((state) => state.groups.groups);

  const users = useSelector((state) => state.groupsharing?.users?.items ?? []); // list of ids
  const sharedcontent = useSelector(
    (state) => state.groupsharing?.content?.items ?? [],
  );
  const folderitems = useSelector(
    (state) => state.search.subrequests?.membraneusers?.items || [],
  );

  const [activeIndex, setActiveIndex] = React.useState(null);

  React.useEffect(() => {
    dispatch(listGroups());
  }, [dispatch]);

  React.useEffect(() => {
    if (activeIndex) {
      dispatch(getGroupUsers(activeIndex));
      dispatch(getSharedContent(activeIndex));
    }
  }, [dispatch, activeIndex]);

  function accordionClickHandler(e, titleProps) {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  }

  // Listing Members
  React.useEffect(() => {
    dispatch(
      searchContent(
        '/',
        {
          portal_type: ['zhkathauthor'],
          // review_state: ['published'],
          b_size: 1000,
          fullobjects: true,
        },
        'membraneusers',
      ),
    );
  }, [dispatch]);

  return (
    <>
      <Plug pluggable="main.toolbar.top" id="export-users">
        <ExportUsers />
      </Plug>
      <Container className="view-wrapper">
        <article id="content">
          <header>
            <h1 className="documentFirstHeading">
              Sharing-View – {content.title}
            </h1>
            {content.description && (
              <p className="documentDescription">{content.description}</p>
            )}
          </header>
          <section id="grouplist">
            <h2>Gruppen</h2>
            <Accordion fluid className="groupssharing-accordion">
              {map(
                groups.filter(
                  (grp) =>
                    ![
                      'AuthenticatedUsers',
                      'Administrators',
                      'Site Administrators',
                      'Reviewers',
                    ].includes(grp.id),
                ),
                (item, index) => (
                  <React.Fragment key={index}>
                    <Accordion.Title
                      active={activeIndex === item.id}
                      index={item.id}
                      onClick={accordionClickHandler}
                    >
                      {activeIndex === item.id ? (
                        <Icon name={downSVG} size="24px" />
                      ) : (
                        <Icon
                          name={rightSVG}
                          size="24px"
                          className={cx({
                            'rotate-arrow': true,
                          })}
                        />
                      )}
                      <span>{item.title}</span>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === item.id}>
                      <AnimateHeight
                        animateOpacity
                        duration={500}
                        height={activeIndex === item.id ? 'auto' : 0}
                      >
                        <div>
                          <b>Mitglieder dieser Gruppe</b>
                        </div>
                        {users.length === 0 ? <div>keine</div> : null}
                        {map(users, (user, index) => {
                          return (
                            <div key={user.id}>{user.fullname ?? user.id}</div>
                          );
                        })}
                        <div>
                          <b>Geteilte Inhalte</b>
                        </div>
                        {sharedcontent.length === 0 ? <div>keine</div> : null}
                        {map(sharedcontent, (item, index) => {
                          return (
                            <div className="sharedcontent" key={index}>
                              <Link to={flattenToAppURL(item['@id'])}>
                                {item.title}
                              </Link>
                            </div>
                          );
                        })}
                      </AnimateHeight>
                    </Accordion.Content>
                  </React.Fragment>
                ),
              )}
            </Accordion>
          </section>
          <Divider />
          <section id="memberlist">
            <h2>Personen</h2>
            {map(folderitems.sort(fullnameSorter), (item, index) => (
              <div className="memberlist-item" key={index}>
                <Link to={item['@id']}>
                  <span>
                    {item.title ?? item.first_name + ' ' + item.last_name}
                    {item.companyposition ? ', ' + item.companyposition : ''}
                  </span>
                </Link>
                <br />
                {item.organisation?.length > 0 && (
                  <span className="organisation discreet">
                    {item.organisation}
                  </span>
                )}
                <br />
                {item.subjects?.length > 0 && (
                  <span className="subjects discreet">
                    {item.subjects.join(', ')}
                  </span>
                )}
              </div>
            ))}
          </section>
        </article>
      </Container>
    </>
  );
};

export default SharingView;
