import React from 'react';
import { injectIntl } from 'react-intl';
import { Container, Table } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';

const PersonView = ({ content, intl, location }) => {
  return (
    <>
      <Container id="person" key="person">
        <h1 className="documentFirstHeading">
          {content.first_name} {content.last_name}
        </h1>
        <Table>
          <Table.Body>
            {content.email && (
              <Table.Row>
                <Table.Cell width={3}>E-Mail</Table.Cell>
                <Table.Cell>
                  <a href={`mailto:${content.email}`}>{content.email}</a>
                </Table.Cell>
              </Table.Row>
            )}
            {content.website && (
              <Table.Row>
                <Table.Cell>Website</Table.Cell>
                <Table.Cell>{content.website}</Table.Cell>
              </Table.Row>
            )}
            {content.telnr && (
              <Table.Row>
                <Table.Cell>Telefon</Table.Cell>
                <Table.Cell>{content.telnr}</Table.Cell>
              </Table.Row>
            )}
            {content.organisation && (
              <Table.Row>
                <Table.Cell>Organisation</Table.Cell>
                <Table.Cell>{content.organisation}</Table.Cell>
              </Table.Row>
            )}
            {content.companyposition && (
              <Table.Row>
                <Table.Cell>Position</Table.Cell>
                <Table.Cell>{content.companyposition}</Table.Cell>
              </Table.Row>
            )}
            {content.kanton && (
              <Table.Row>
                <Table.Cell>Kanton</Table.Cell>
                <Table.Cell>{content.kanton}</Table.Cell>
              </Table.Row>
            )}
            {content.bio?.data && (
              <Table.Row>
                <Table.Cell></Table.Cell>
                <Table.Cell>
                  <div dangerouslySetInnerHTML={{ __html: content.bio.data }} />
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Container>
    </>
  );
};

export default injectIntl(PersonView);
