import {
  GET_GROUPUSERS,
  GET_SHAREDCONTENT,
  GET_SHARINGPLUS,
} from '../constants/ActionTypes';

const initialStateGroupsharing = {
  users: {},
  content: {},
  // items: [],
  loaded: false,
  loading: false,
  error: null,
};

export function groupsharingReducer(
  state = initialStateGroupsharing,
  action = {},
) {
  switch (action.type) {
    case `${GET_GROUPUSERS}_PENDING`:
    case `${GET_SHAREDCONTENT}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${GET_GROUPUSERS}_SUCCESS`:
      return {
        ...state,
        users: action.result,
        error: null,
        loaded: true,
        loading: false,
      };
    case `${GET_SHAREDCONTENT}_SUCCESS`:
      return {
        ...state,
        content: action.result,
        error: null,
        loaded: true,
        loading: false,
      };
    case `${GET_GROUPUSERS}_FAIL`:
    case `${GET_SHAREDCONTENT}_FAIL`:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
}

const initialStateGroupsharingPlus = {
  data: {},
  loaded: false,
  loading: false,
  error: null,
  subrequests: {},
};

export function groupsharingcontentsReducer(
  state = initialStateGroupsharingPlus,
  action = {},
) {
  switch (action.type) {
    case `${GET_SHARINGPLUS}_PENDING`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                ...(state.subrequests[action.subrequest] || {
                  data: {},
                }),
                error: null,
                loaded: false,
                loading: true,
              },
            },
          }
        : {
            ...state,
            data: {},
            error: null,
            loading: true,
            loaded: false,
          };
    case `${GET_SHARINGPLUS}_SUCCESS`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                error: null,
                data: action.result,
                loaded: true,
                loading: false,
              },
            },
          }
        : {
            ...state,
            error: null,
            data: action.result,
            loaded: true,
            loading: false,
          };
    case `${GET_SHARINGPLUS}_FAIL`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                error: action.error,
                data: initialStateGroupsharingPlus.data,
                loading: false,
                loaded: false,
              },
            },
          }
        : {
            ...state,
            error: action.error,
            data: initialStateGroupsharingPlus.data,
            loading: false,
            loaded: false,
          };
    default:
      return state;
  }
}
