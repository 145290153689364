export function getColor(string) {
  switch (string) {
    case 'internally_published':
      return '#f4e037';
    case 'private':
      return '#ed4033';
    case 'disabled':
      return '#ed4033';
    case 'published':
      return '#f4e037';
    case 'enabled':
      return '#007bc1';
    case 'intranet':
      return '#51aa55';
    case 'draft':
      return '#f6a808';
    case 'internal':
      return '#f68b08';
    default:
      return 'gray';
  }
}
